import React from 'react';
import './Home.css';

import IndexMap from './indexMap/IndexMap';
import Menu from './menu/Menu';
import DeveloperPanel from './developerPanel/DeveloperPanel';
import MapService from './mapService/MapService';
import AppPanel from './appPanel/AppPanel';
import DataVisualization from './dataVisualization/DataVisualization';
import PeoPleWork from './peopleWork/PeoPleWork';
import Blogroll from './blogroll/Blogroll';
import RuralRevitalization from './ruralRevitalization/RuralRevitalization';
import ThematicChannels from './thematicChannels/ThematicChannels.js';
import Footer from '../../../components/footer/Footer';

class Home extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={'home-container'}>
        <IndexMap />
        <Menu />
        <MapService />
        <DeveloperPanel />
        {/* <div className={'peopleWork-dataVisualization-panel'}>
          <PeoPleWork />
          <RuralRevitalization />
          <DataVisualization />
        </div>
        <AppPanel />
        <Blogroll /> */}
        <ThematicChannels />
        <Footer version={0} />
      </div>
    );
  }
}
export default Home;
