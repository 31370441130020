import React from 'react';
import './Header.css';
import HeaderNavigation from './HeaderNavigation.js';
import System from '../../components/config/System';
import {
  getToken,
  getZWToken,
  removeToken,
  removeZWToken
} from '../../modules/CookieTools';

import $ from 'jquery';
import { Carousel } from 'antd';
import { Link } from 'react-router-dom';
import { Store } from '../../store/Store';
import defined from '../../modules/defined';
import { removeName, removeUser } from '../../store/reducers/UserSore';

class Header extends React.Component {
  constructor(props) {
    super(props);
    window.isLoading = false;
    this.loginRegisterHiddenClass =
      'headerHintCon-login-register-vessel-hidden';
    this.loginRegisterShowClass = 'headerHintCon-login-register-vessel-show';

    this.userInfoHiddenClass = 'headerHintCon-userInfo-hidden';
    this.userInfoShowClass = 'headerHintCon-userInfo-show';

    this.loginHiddenClass = 'headerHintCon-login-vessel-hidden';
    this.loginShowClass = 'headerHintCon-login-vessel-show';

    this.registerHiddenClass = 'headerHintCon-register-vessel-hidden';
    this.registerShowClass = 'headerHintCon-register-vessel-show';

    this.state = {
      loginRegisterClass: this.loginRegisterShowClass,
      userInfoClass: this.userInfoHiddenClass,
      loginClass: this.loginHiddenClass,
      registerClass: this.registerHiddenClass,
      userInfoName: '',
      typeId: '',
      newsList: []
    };
    window.isLoading = false;
  }

  componentDidMount() {
    let userName = Store.getState().user.userName;
    if (defined(userName) && defined(getToken())) {
      //天地图登录
      this.setState({ userInfoName: userName });
      this.setState({
        userInfoUrl: 'https://uums.tianditu.gov.cn/enterpriseInfo'
      });
      this.setState({ loginRegisterClass: this.loginRegisterHiddenClass });
      this.setState({ userInfoClass: this.userInfoShowClass });
    } else if (defined(userName) && defined(getZWToken())) {
      //政务网登录
      this.setState({ userInfoName: userName });
      this.setState({
        userInfoUrl:
          'https://zwfw.yn.gov.cn/portal/#/ynuser-center/modify-personal-info'
      });
      this.setState({ loginRegisterClass: this.loginRegisterHiddenClass });
      this.setState({ userInfoClass: this.userInfoShowClass });
    } else {
      //没有登录
      this.setState({ loginRegisterClass: this.loginRegisterShowClass });
      this.setState({ userInfoClass: this.userInfoHiddenClass });
    }

    // 获取公告
    this.loadNewsColumnData();
  }

  //退出登录---按钮
  exitClick() {
    let userName = Store.getState().user.userName;
    if (defined(userName) && defined(getToken())) {
      //天地图登录
      this.exitLoadTianditu();
    } else if (defined(userName) && defined(getZWToken())) {
      //政务网登录
      this.exitLoadZw();
    }
  }
  //退出天地图。
  exitLoadTianditu() {
    let that = this;
    $.ajax({
      url: System.gateway + '/auth/tianditu/localLogout',
      type: 'GET',
      dataType: 'json',
      async: true,
      beforeSend: function (request) {
        request.setRequestHeader('Tdt-S-Token', getToken());
        request.setRequestHeader('Tdt-S-App-Id', System.sAppId);
      },
      success: function (data) {
        that.removeCookieLocal();
        window.open(
          System.gateway +
            '/auth/tianditu/casLogout?redirectUrl=' +
            System.publishSite,
          '_self'
        );
      },
      error: function (err) {
        that.removeCookieLocal();
        window.open(
          System.gateway +
            '/auth/tianditu/casLogout?redirectUrl=' +
            System.publishSite,
          '_self'
        );
      }
    });
  }
  //退出政务。
  exitLoadZw() {
    let that = this;
    $.ajax({
      url: System.gateway + '/yntdtsite/ynzwsso/logout?token=' + getZWToken(),
      type: 'GET',
      dataType: 'json',
      async: true,
      xhrFields: {
        withCredentials: true // 这里设置了withCredentials
      },
      success: function (data) {
        that.removeCookieLocal();
        console.log('退出政务', data);
      },
      error: function (err) {
        console.log('退出政务失败', err);
      }
    });
  }

  //删除cookie--显示登陆注册
  removeCookieLocal() {
    this.setState({ loginRegisterClass: this.loginRegisterShowClass });
    this.setState({ userInfoClass: this.userInfoHiddenClass });
    removeToken();
    removeZWToken();
    Store.dispatch(removeUser());
    Store.dispatch(removeName());
  }

  render() {
    return (
      <div className={'header-con'}>
        <div className={'headerHint'}>
          <div className={'headerHintCon'}>
            <div className={'headerHintCon-news'}>
              <div title={'更多'} className={'headerHintCon-news-icon'}>
                <Link to={'/support/news'}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/staticImg/header/logo_trumpet.png'
                    }
                  />
                </Link>
              </div>
              <div className={'headerHintCon-news-con'}>
                {
                  <Carousel autoplay dots={false} dotPosition={'left'}>
                    {this.state.newsList.map((item, key) => (
                      <div key={key} className={'headerHintCon-news-con-item'}>
                        {item.noticeLink === '_self' ? (
                          <Link to={item.router}>
                            <strong>{'[ ' + item.year + ' ]'}</strong>
                            {item.noticeTitle}
                          </Link>
                        ) : (
                          <a href={item.noticeUrl} target={'_blank'}>
                            <strong>{'[ ' + item.year + ' ]'}</strong>
                            {item.noticeTitle}
                          </a>
                        )}
                      </div>
                    ))}
                  </Carousel>
                }
              </div>
            </div>
            <div className={'headerHintCon-login-register'}>
              {/*登录注册*/}
              <div className={this.state.loginRegisterClass}>
                <div className={'headerHintCon-login'}>
                  <span
                    onMouseLeave={this.loginMouseLeave.bind(this)}
                    onMouseOver={this.loginMouseOver.bind(this)}
                  >
                    {'登录'}
                  </span>
                  <div
                    onMouseLeave={this.loginMouseLeave.bind(this)}
                    onMouseOver={this.loginMouseOver.bind(this)}
                    className={this.state.loginClass}
                  >
                    <div className={'headerHintCon-login-vessel-icon'}>
                      <i className={'iconfont'}>&#xe616;</i>
                    </div>
                    <div className={'headerHintCon-login-vessel-test'}>
                      <div className={'headerHintCon-login-vessel-tdt'}>
                        <i className={'iconfont'}>&#xe612;</i>
                        <a
                          href={
                            System.gateway +
                            '/auth/tianditu/casLogin?sAppId=' +
                            System.sAppId +
                            '&redirectUrl=' +
                            System.publishSite
                          }
                        >
                          天地图用户
                        </a>
                      </div>
                      <div className={'headerHintCon-login-vessel-zw'}>
                        <i className={'iconfont'}>&#xe612;</i>
                        <a
                          href={
                            'https://zwfw.yn.gov.cn/portal/#/login?C-App-Id=DLXXFW_APP_YN_001&backUrl=' +
                            System.publishSite
                          }
                        >
                          政务用户
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'headerHintCon-register'}>
                  <span
                    onMouseLeave={this.registerMouseLeave.bind(this)}
                    onMouseOver={this.registerMouseOver.bind(this)}
                  >
                    {'注册'}
                  </span>
                  <div
                    onMouseLeave={this.registerMouseLeave.bind(this)}
                    onMouseOver={this.registerMouseOver.bind(this)}
                    className={this.state.registerClass}
                  >
                    <div className={'headerHintCon-register-vessel-icon'}>
                      <i className={'iconfont'}>&#xe616;</i>
                    </div>
                    <div className={'headerHintCon-register-vessel-test'}>
                      <div className={'headerHintCon-register-vessel-tdt'}>
                        <i className={'iconfont'}>&#xe611;</i>
                        <a href="https://uums.tianditu.gov.cn/register">
                          天地图注册
                        </a>
                      </div>
                      <div className={'headerHintCon-register-vessel-zw'}>
                        <i className={'iconfont'}>&#xe611;</i>
                        <a
                          href={
                            'https://zwfw.yn.gov.cn/portal/#/regist?backUrl=https://yunnan.tianditu.gov.cn&refer=NA'
                          }
                        >
                          政务注册
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*用户信息*/}
              <div className={this.state.userInfoClass}>
                <a target={'_blank'} href={this.state.userInfoUrl}>
                  {this.state.userInfoName}
                </a>
                <i
                  onClick={this.exitClick.bind(this)}
                  title={'退出'}
                  className={'iconfont'}
                >
                  &#xe613;
                </i>
              </div>
              {/*返回国家节点*/}
              <div className={'headerHintCon-returnMasterNode'}>
                <a title={'返回国家节点'} href="https://www.tianditu.gov.cn/">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/staticImg/header/loge_countryHome.png'
                    }
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={'headerTitle'}>
          <div className={'headerTitleCon'}>
            <img
              src={process.env.PUBLIC_URL + '/staticImg/header/logo_ynmap.png'}
            />
          </div>
        </div>
        <HeaderNavigation />
      </div>
    );
  }

  loadNewsColumnData() {
    fetch(
      System.gateway +
        '/tdtyns/TdtNotice/list?pageNum=1&pageSize=8&siteId=' +
        System.siteValue,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        mode: 'cors',
        cache: 'default'
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.code !== 200) {
          alert('查询新闻失败');
          return;
        }
        let rows = data.rows;

        for (let item of rows) {
          item.year = item.noticeDate.substring(0, 10);
          switch (item.noticeType) {
            case '更新日志': {
              item.router = '/supportInfo/logUpdate/' + item.noticeId;
              break;
            }
            case '新闻公告': {
              item.router = '/supportInfo/news/' + item.noticeId;
              break;
            }
          }
        }

        this.setState({ newsList: rows });
      });
  }

  //登录
  loginMouseOver(target) {
    this.setState({ loginClass: this.loginShowClass });
  }

  loginMouseLeave(target) {
    this.setState({ loginClass: this.loginHiddenClass });
  }

  //注册
  registerMouseOver(target) {
    this.setState({ registerClass: this.registerShowClass });
  }

  registerMouseLeave(target) {
    this.setState({ registerClass: this.registerHiddenClass });
  }
}

export default Header;
