import React from 'react';
import './LogUpdate.css';
import System from "../../../config/System";
import $ from "jquery";
import {Card, Col, Empty, Image, Row} from "antd";
import {Link} from "react-router-dom";

class LogUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.downIcon = '<i class="iconfont logUpdate">&#xe644;</i>';
        this.rightIcon = '<i class="iconfont logUpdate">&#xe645;</i>';

        this.typeNameShowClass = "logUpdate-content-nav-item-con-show";
        this.typeNameHiddenClass = "logUpdate-content-nav-item-con-hidden";

        this.yearUnClass = "logUpdate-content-nav-item-con-year-un";
        this.yearHasClass = "logUpdate-content-nav-item-con-year-has";
        this.state = {
            content: [],
            yearHint:"网站更新",
            year: 0,

            titleType:[],
            nowYears:[]
        };
        this.titleType = [];
        this.activeTypeIndex = 0;
        this.activeYearIndex = 0;
    }
    componentDidMount() {
        //获取类型
        let that = this;
        let url = System.gateway + '/tdtyns/TdtUpdateLogType/list?delFlag=1&siteId=' + System.siteValue;
        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            async: true,
            success: function (data) {
                let titleTypes = [];
                let rows = data.rows;
                if (rows.length === 0) {
                    return;
                }
                for (let item of rows) {
                    let titleType = {};
                    let years = that.getYearsByType(item.id);
                    titleType.titleType = item;
                    titleType.years = years;
                    titleTypes.push(titleType)
                }
                that.setState({titleType: titleTypes});
                //设置默认打开第一个
                that.titleNameClick(0,0,titleTypes[0],titleTypes[0].years[0],undefined)
                //查询第一个年份数据--加载
            },
            error: function (err) {
                alert("查询类型失败");
                console.log(err);
            }
        });
    }
    //根据类型获取年份
    getYearsByType(dataType){
        let rows = [];
        let url = System.gateway + '/tdtyns/TdtUpdateLogNew/getYearsByType?dataUpdateType=' + dataType + '&siteId=' + System.siteValue;
        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            async:false,
            success: function (data) {
                if (data.data.length !== 0) {
                    rows = data.data.sort().reverse();
                }
            },
            error: function (err) {
                alert("查询年份失败");
                console.log(err);
            }
        });
        return rows;
    }

    //类型点击事件
    titleNameClick(key,index,item,year,target){
        //关闭已经打开
        this.setState({["typeName_" + this.activeTypeIndex]: this.typeNameHiddenClass,["typeIcon_" + this.activeTypeIndex]: this.rightIcon});
        this.setState({["typeName_" + key]: this.typeNameShowClass,["typeIcon_" + key]: this.downIcon});
        this.yearNameClick(key,index,item,year,undefined)
    }
    //年份点击事件
    yearNameClick(key,index,item,year,target){
        this.setState({["yearSelect_" + this.activeTypeIndex + "_" + this.activeYearIndex]: this.yearUnClass});
        this.setState({["yearSelect_" + key + "_" + index]: this.yearHasClass});
        this.activeTypeIndex = key;
        this.activeYearIndex = index;

        this.loadingDataByYear(item.titleType.id, year,item.titleType.typeName)
    }


    //查询第一个年份数据--加载
    loadingDataByYear(typeId, year,typeName){
        if (year === undefined || year === "" || year === null) {
            return;
        }
        let that = this;
        this.setState({yearHint: typeName});
        this.setState({year: year});
        let url = System.gateway + '/tdtyns/TdtUpdateLogNew/getDataListByYear?delFlag=1&dataUpdateType=' + typeId + '&dataUpdateTime=' + year + "-1-1"+ '&siteId=' + System.siteValue;
        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            async: true,
            success: function (data) {
                that.setState({content: data.data});
                that.timeLineTogether(); //内容  时间轴展开
            },
            error: function (err) {
                alert("查询年份失败");
                console.log(err);
            }
        });
    }

    render() {
        return (
            <div className={"logUpdate-content"}>
                <div className={"logUpdate-content-nav"}>
                    {
                        this.state.titleType.map((item,key)=>
                            <div className={"logUpdate-content-nav-item"} key={key}>
                                <div onClick={this.titleNameClick.bind(this,key,0,item,item.years[0])} className={"logUpdate-content-nav-item-title"}>
                                    <span>{item.titleType.typeName}</span>
                                    <div className={"logUpdate-content-nav-item-title-icon"}
                                         dangerouslySetInnerHTML={{__html:this.state["typeIcon_" + key]===undefined? this.rightIcon:this.state["typeIcon_" + key]}}/>
                                </div>
                                <div className={this.state["typeName_" + key]===undefined? this.typeNameHiddenClass:this.state["typeName_" + key]}>
                                    {
                                        item.years.map((year, index) =>
                                            <div onClick={this.yearNameClick.bind(this,key,index,item,year)}
                                                 className={this.state["yearSelect_" + key + "_" + index]===undefined?this.yearUnClass:this.state["yearSelect_" + key + "_" + index]} key={index}>{year}</div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
                <div id={"logUpdate-content-con"} className={"logUpdate-content-con"}>
                    <div className={"logUpdate-content-con-heard"}>
                        <span>{"时间轴"}</span>
                        <div className={"logUpdate-content-con-heard-year"}>{this.state.year}</div>
                    </div>
                    <div className={"logUpdate-content-con-body"}>
                        <div className={"logUpdate-content-con-body-con"}>
                            {
                                this.state.content.map((item, key) =>
                                    <div key={key} className={"logUpdate-content-con-body-con-item"}>
                                        <div className={"logUpdate-content-con-body-con-item-time"}>
                                            <span>{item.dataUpdateTime.substring(5,10)}</span>
                                        </div>
                                        <div  className={"logUpdate-content-con-body-con-item-timeCode"}>
                                            <i className={"iconfont logUpdate"}>&#xe7c2;</i>
                                        </div>
                                        <div className={"logUpdate-content-con-body-con-item-right"}>
                                            <i className={"iconfont logUpdate"}>&#xe637;</i>
                                        </div>
                                        <div className={"logUpdate-content-con-body-con-item-des"}>
                                            <Link to={'/supportInfo/logUpdate/' + item.id}>
                                                <Card
                                                    hoverable={true}
                                                    size="small"
                                                    title={this.displayTitle(item.title)}
                                                    style={{
                                                        width: 630,
                                                        borderColor: "#d9d9d9",
                                                        borderRadius: 10
                                                    }}
                                                    headStyle={{
                                                        borderColor: "#d9d9d9",
                                                        overflow: "hidden"
                                                    }}
                                                >
                                                    <div className={"logUpdate-content-con-body-con-item-card"}>
                                                        <div className={".logUpdate-content-con-body-con-item-dataUpdateText"} style={{fontSize: 14,color: "#939393",textIndent: 28}}>
                                                            {item.dataUpdateText}
                                                        </div>
                                                        <div className={"logUpdate-content-con-body-con-item-image"}>
                                                            {item.thumbnail !== null? (
                                                                <Image
                                                                    preview={false}
                                                                    width={80}
                                                                    height={80}
                                                                    src={System.staticSourceUrl + item.thumbnail}
                                                                />
                                                            ):(
                                                                <Empty image={"https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"}
                                                                       imageStyle={{
                                                                           height: 50,
                                                                       }}
                                                                       description={
                                                                           <span style={{fontSize:10,color:"#939393"}}>暂无图片</span>
                                                                       }>
                                                                </Empty>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Link>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className={"logUpdate-content-con-body-line"}/>
                    </div>
                </div>
            </div>
        );
    }

    //内容  时间轴展开
    timeLineTogether(){
        let element = document.getElementById("logUpdate-content-con");

        let start = 0;
        let timer = setInterval(frame, 1);
        let that = this;
        function frame() {
            if (start === 780) {
                clearInterval(timer);
                that.isTimeLine = false;
            } else {
                start += 3;
                element.style.height = start + "px";
            }
        }
    }
    //卡片标题
    displayTitle(title){
        return (
            <div style={{fontSize:'15px',color:'#0080c8'}}>
                {title}
            </div>
        )
    }

}
export default LogUpdate;
