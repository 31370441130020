const System = {};

// System.gateway = "http://127.0.0.1:9000/system";//测试服务器---新版接口
//  System.gateway = "http://yunnan.tianditu.gov.cn:8081";//测试服务器---接口
// System.gateway = "http://127.0.0.1:8081";//开发服务器---接口
// System.gateway = "https://ynsso.ynmap.cn/yntdt";//测试服务器---接口
System.gateway = "https://sso.ynmap.cn/yntdt";//部署地址---接口

System.staticSourceUrl = "https://sso.ynmap.cn/staticSource";

System.publishSite = "https://yunnan.tianditu.gov.cn";//发布地址
// System.publishSite = "https://ynsso.ynmap.cn/yunnan";//发布地址

System.siteValue = "530000000000";
System.indexMapCoordinate = [102.70899531385986, 25.047526053605647];//首页地图定位点
System.onlineMapUrl = "https://yunnan.tianditu.gov.cn/onlineMap";//在线地图地址
System.tdtKey = "b0ad70dc306d789204ddb4ec0b7c2b4d";//在线地图key--国家
System.yntdtKey = "c3fcc38488a047cab6d4b55c21c76cbb";//在线地图key--云南

System.sAppId = "d5672f80-d850-48a5-8d2a-87420c04b9d9";//子系统ID--云南

/***    部署其它路劲：需要改package.json中的homepage名称，和index.js中的basename   ***/
export default System;
