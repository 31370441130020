import React from 'react';
import './TermsService.css';

class TermsService extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            termsService: []
        }
    }
    componentDidMount(){
        fetch(process.env.PUBLIC_URL + '/staticData/body/support/termsService/TermsService.json', {
            method: 'GET'
        }).then(res => res.json()).then(data => {
            this.setState({termsService: data.termsService});
        })
    }
    render() {
        return (
            <div id={"termsService-content"} className={"termsService-content"}>
                {
                    this.state.termsService.map((termsService, key) =>
                        termsService.childs ?
                            <p key={key} className={"termsService-content-paragraph-tow"}>
                                {termsService.paragraph}
                            </p>:
                            <p key={key} className={"termsService-content-paragraph"}>
                                {termsService.paragraph}
                            </p>
                    )
                }
            </div>
        );
    }
}
export default TermsService;
