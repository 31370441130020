import React from 'react';
import './Blogroll.css';

class Blogroll extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "友情链接",
            BlogrollCenter: [],
        };
    }

    componentDidMount() {
        fetch(process.env.PUBLIC_URL + '/staticData/homePage/blogroll/Blogroll.json', {method: "GET"}).then(res => res.json()).then(data => {
            this.setState({BlogrollCenter: data.items});
        });
    }

    render() {
        return (
            <div className={'Blogroll'}>
                <div className={'Blogroll-title'}>
                    <div className={"home-developer-panel-title-tabs"}>
                        <span>{this.state.title}</span>
                    </div>
                </div>
                <div className={'Blogroll-container'}>
                    {
                        this.state.BlogrollCenter.map((item, index) =>
                            <a href={item.href} target={item.target} title={item.name} className={"Blogroll-container-item"} key={index}>
                                <span>{item.name}</span>
                                <p>{"进入>>"}</p>
                            </a>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default Blogroll;
