import React from 'react';
import './Menu.css';
import { Link } from 'react-router-dom';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };
    this.menu = [];
  }

  componentDidMount() {
    fetch(process.env.PUBLIC_URL + '/staticData/homePage/menu/Menu.json', {
      method: 'GET'
    })
      .then((res) => res.json())
      .then((data) => {
        this.menu = data.items;
        this.setState({ loaded: true });
      });
  }

  render() {
    return (
      <div className={'home-menu'}>
        {this.menu.map((item, index) => (
          <div
            className={'menu-item'}
            key={index}
          >
            {item.target === '_self' ? (
              <Link to={item.href}>
                <img
                  src={
                    this.state['menu_' + index] === undefined ||
                      !this.state['menu_' + index]
                      ? process.env.PUBLIC_URL + item.icon
                      : process.env.PUBLIC_URL + item.iconH
                  }
                />
                <span>{item.name}</span>
              </Link>
            ) : (
              <a href={item.href} target={item.target}>
                <img
                  src={
                    this.state['menu_' + index] === undefined ||
                      !this.state['menu_' + index]
                      ? process.env.PUBLIC_URL + item.icon
                      : process.env.PUBLIC_URL + item.iconH
                  }
                />
                <span>{item.name}</span>
              </a>
            )}
          </div>
        ))}
      </div>
    );
  }
}
export default Menu;
