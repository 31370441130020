/**
 * undefined,"",null，数组【0】为：false，否则：true
 * @param value
 * @returns {boolean}
 */
function defined(value) {
    let con = true;
    if (value instanceof Array){
        con = value.length !== 0;
    }else if (typeof value === "string"){
        con = value !== '';
    }
    return value !== undefined && value !== null && con;
}

export default defined;