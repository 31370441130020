import React from 'react';
import './PagingButton.css';

/**
 分页显示---------公共模块
 */
class PagingButton extends React.Component {
    constructor(props) {
        super(props);
        this.leftIcon = '<i class="iconfont">&#xeb8e;</i>';
        this.rightIcon = '<i class="iconfont">&#xeb8f;</i>';
        this.leftMoreIcon = '<i class="iconfont">&#xe64c;</i>';
        this.rightMoreIcon = '<i class="iconfont">&#xe649;</i>';
        this.omitIcon = '<i class="iconfont">&#xe638;</i>';
        this.cutPage = "pagingButton-common-cutPage";
        this.forbidPage = "pagingButton-common-cutPage-forbid";

        this.moreShowClass = "pagingButton-common-nowPage-more-show";
        this.moreHidenClass = "pagingButton-common-nowPage-more-hidden";

        this.state = {
            leftIcon: this.leftIcon,
            rightIcon: this.rightIcon,
            amountPage: 0,
            leftMore: this.omitIcon,
            rightMore: this.omitIcon,
            leftMoreClass: this.moreHidenClass,
            rightMoreClass: this.moreHidenClass,

            upDisabled: false,
            downDisabled: false,
            upPage: this.cutPage,
            downPage: this.cutPage,
            pageArr: [1]
        };
        this.pageArr = [1];
        this.pageSize = 10;
        this.activePageNumber = 1;
        this.activeIndex = 0;
        this.oldIndex = 0;

        this.pageArrSize = 6;
        this.amountSize = 1;
        this.activeAmount = 1;

        this.amountPage = 1
    }
    componentDidMount() {
        this.props.onRef(this);
        this.amountPage = this.props.getPageAmount();
    }
    setAmountPage(value){
        this.amountPage = value;
        this.loadingData();
    }
    //加载方法
    loadingData(){
        //获取总页数
        if (this.amountPage%this.pageArrSize === 0){
            this.amountSize = this.amountPage/this.pageArrSize;
        }else {
            this.amountSize = Math.floor(this.amountPage/this.pageArrSize) + 1;
        }
        this.setState({amountPage: this.amountPage});//更新总页数
        this.setPageArr();
        this.pageNumberClick(0,undefined);
    }

    render() {
        return (
            <div className={"pagingButton-common"}>
                <div id={"pagingButton-common-amount"} className={"pagingButton-common-amount"}>
                    {"共 " + this.state.amountPage + " 页"}
                </div>
                <button disabled={this.state.upDisabled} title={"上一页"} onClick={this.upPageBut.bind(this)} className={this.state.upPage}
                     dangerouslySetInnerHTML={{__html:this.state.leftIcon}}
                >
                </button>
                <div className={"pagingButton-common-nowPage"}>
                    <div onClick={this.leftMoreClick.bind(this,0)} onMouseLeave={this.leftMoreMouseLeave.bind(this)} onMouseOver={this.leftMoreMouseOver.bind(this)} title={"更多"}className={this.state.leftMoreClass}
                         dangerouslySetInnerHTML={{__html:this.state.leftMore}}
                    >
                    </div>

                    <div className={"pagingButton-common-nowPage-pageNumber"}>
                        {
                            this.state.pageArr.map((item, key)=>
                                <button className={this.state["pageNumberClass" + key]} onClick={this.pageNumberClick.bind(this,key)} key={key}>{item}</button>
                            )
                        }
                    </div>
                    <div onClick={this.rightMoreClick.bind(this,0)} onMouseLeave={this.rightMoreMouseLeave.bind(this)} onMouseOver={this.rightMoreMouseOver.bind(this)} title={"更多"} className={this.state.rightMoreClass}
                         dangerouslySetInnerHTML={{__html:this.state.rightMore}}
                    >
                    </div>
                </div>
                <button disabled={this.state.downDisabled} title={"下一页"} onClick={this.downPagCut.bind(this)} className={this.state.downPage}
                     dangerouslySetInnerHTML={{__html:this.state.rightIcon}}
                >
                </button>
                <div className={"pagingButton-common-jumpToPage"}>
                    <div className={"pagingButton-common-jumpToPage-des"}>
                        跳转到
                    </div>
                    <input defaultValue={1} type={"number"} onInput={this.RestrictedInputInteger.bind()} id={"pagingButton-common-jumpToPage-number"} className={"pagingButton-common-jumpToPage-number"}/>
                    <div className={"pagingButton-common-jumpToPage-page"}>
                        页
                    </div>
                    <button onClick={this.jumpToPage.bind(this)} className={"pagingButton-common-jumpToPage-but"}>
                        确定
                    </button>
                </div>
            </div>
        );
    }

    //上一页
    upPageBut(){
        if (this.activeIndex <= 0) {
            this.leftMoreClick(5);
            return;
        }
        this.activeIndex = this.activeIndex - 1;
        this.setPageArr();
        this.pageNumberClick(this.activeIndex);
    }
    //下一页
    downPagCut(){
        this.activeIndex = this.activeIndex + 1;
        if (this.activeIndex >= 6) {
           this.rightMoreClick(0);
           return;
        }
        this.setPageArr();
        this.pageNumberClick(this.activeIndex);
    }
    //右---更多--
    rightMoreClick(index,target){
        this.activeIndex = index;
        if (this.activeAmount < this.amountSize) {
            this.activeAmount = this.activeAmount + 1;
        }else {
            this.activeAmount = this.amountSize;
        }
        this.setPageArr();

        this.pageNumberClick(this.activeIndex);
    }
    //左---更多--
    leftMoreClick(index,target){
        this.activeIndex = index;
        if (this.activeAmount > 1) {
            this.activeAmount = this.activeAmount - 1;
        }else {
            this.activeAmount = this.amountSize;
        }
        this.setPageArr();

        this.pageNumberClick(this.activeIndex);
    }
    //跳转到第几页---确定
    jumpToPage(){
        let element = document.getElementById("pagingButton-common-jumpToPage-number");
        let value = parseInt(element.value);
        if (value <= 0) {
            value = 1;
        }
        if (value > this.amountPage) {
            value = this.amountPage;
        }
        if (isNaN(value)) {
            value = 1;
        }
        let index = (value - 1) % this.pageArrSize;
        this.activeAmount = Math.floor((value - 1)/this.pageArrSize) + 1;
        this.setPageArr();
        this.pageNumberClick(index);
    }

    //设置当前页 --并搜索
    pageNumberClick(index,target){
        if (this.pageArr[index] !== undefined) {
            this.activePageNumber = this.pageArr[index];
        }

        this.changeNowPageColor(index);
        this.props.pagingQuery(this.activePageNumber,this.pageSize);

        let jumpToPageValueE = document.getElementById("pagingButton-common-jumpToPage-number");
        jumpToPageValueE.value = this.activePageNumber;

        if (this.activePageNumber === 1 && this.amountPage === 1){
            this.setState({upPage: this.forbidPage,downPage: this.forbidPage});
            this.setState({upDisabled: true,downDisabled: true});

        }else if (this.activePageNumber === 1 && this.amountPage !== 1){
            this.setState({upPage: this.forbidPage,downPage: this.cutPage});
            this.setState({upDisabled: true,downDisabled: false});

        }else if (this.activePageNumber === this.amountPage && this.activePageNumber !== 1){
            this.setState({upPage: this.cutPage,downPage: this.forbidPage});
            this.setState({upDisabled: false,downDisabled: true});
        }else {
            this.setState({upPage: this.cutPage,downPage: this.cutPage});
            this.setState({upDisabled: false,downDisabled: false});
        }
    }

    //改变当前页颜色
    changeNowPageColor(index){
        this.setState({["pageNumberClass" + this.oldIndex] : ""});
        this.activeIndex = index;
        this.setState({["pageNumberClass" + index] : "pageNumber-active"});
        this.oldIndex = index;
    }

    //限制输入整数
    RestrictedInputInteger(e){
        let value = e.target.value;
        if(!/^.$/.test(value)){
            value=value.replace(/\D/g,'')
        }
        e.target.value = value;
    }

    //左移入--更多
    leftMoreMouseOver(){
        this.setState({leftMore: this.leftMoreIcon});
    }
    //左移出--更多
    leftMoreMouseLeave(){
        this.setState({leftMore: this.omitIcon});
    }
    //右移出--更多
    rightMoreMouseOver(){
        this.setState({rightMore: this.rightMoreIcon});
    }
    //右移入--更多
    rightMoreMouseLeave(){
        this.setState({rightMore: this.omitIcon});
    }

    //设置页码数组---切换按钮的相应设置
    setPageArr(){
        if (this.amountPage <= 0){
            return;
        }
        let pageArrs = [];
        let startPage = 1;
        let endPage = 1;
        if (this.activeAmount === 1 && this.amountSize === 1) {
            this.setState({leftMoreClass: this.moreHidenClass,rightMoreClass: this.moreHidenClass});
            startPage = (this.activeAmount - 1)*this.pageArrSize + 1;
            endPage = this.amountPage;
        }else if (this.activeAmount === 1 && this.amountSize !== 1) {
            startPage = (this.activeAmount - 1)*this.pageArrSize + 1;
            endPage = (startPage + this.pageArrSize) - 1;
            this.setState({leftMoreClass: this.moreHidenClass,rightMoreClass: this.moreShowClass});

        }else if (this.activeAmount > 1 && this.amountSize === this.activeAmount) {
            startPage = (this.activeAmount - 1)*this.pageArrSize + 1;
            endPage = this.amountPage;
            this.setState({leftMoreClass: this.moreShowClass,rightMoreClass: this.moreHidenClass});
        }else {
            startPage = (this.activeAmount - 1)*this.pageArrSize + 1;
            endPage = (startPage + this.pageArrSize) - 1;
            this.setState({leftMoreClass: this.moreShowClass,rightMoreClass: this.moreShowClass});
        }

        for (let i = startPage; i <= endPage; i++) {
            pageArrs.push(i)
        }
        this.setState({pageArr: pageArrs});
        this.pageArr = pageArrs;
    }
}
export default PagingButton;
