import React from 'react';
import './Exceptions.css';

class Exceptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            termsService: []
        }
    }
    componentDidMount(){
        //数据
        fetch(process.env.PUBLIC_URL + '/staticData/body/support/exceptions/ExceptionsData.json', {
            method: 'GET'
        }).then(res => res.json()).then(data => {
            this.setState({termsService: data.termsService});
        })
    }
    render() {
        return (
            <div id={"termsService-content"} className={"termsService-content"}>
                {
                    this.state.termsService.map((termsService, key) =>
                        termsService.childs === 0 ?
                            <p key={key} className={"termsService-content-paragraph-one"}>
                                {termsService.paragraph}
                            </p>:
                            termsService.childs ?
                                <p key={key} className={"termsService-content-paragraph-tow"}>
                                    {termsService.paragraph}
                                </p>:
                                <p key={key} className={"termsService-content-paragraph"}>
                                    {termsService.paragraph}
                                </p>
                    )
                }
            </div>
        );
    }
}
export default Exceptions;
