import React from 'react';
import './AboutUs.css';
import { Anchor } from 'antd';
const { Link } = Anchor;

class AboutUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            aboutUs: [],
            organization: [],
            leadership: []
        }
    }

    componentDidMount() {
        fetch(process.env.PUBLIC_URL + '/staticData/body/support/aboutUs/AboutUs.json', {
            method: 'GET'
        }).then(res => res.json()).then(data => {
            this.setState({aboutUs: data.aboutUs,organization:data.organization,leadership:data.leadership});
        })
    }

    render() {
        return (
            <div className={"aboutUs-panel"}>
                <div className={"aboutUs-content-Anchor"}>
                    <Anchor style={{paddingTop:"20px"}}>
                        <Link href="#about—us-content" title="单位简介" />
                        <Link href="#organization-content" title="组织架构" />
                        <Link href="#leadership-content" title="领导信息" />
                    </Anchor>
                </div>
                <div className={"aboutUs-content-centre"}>
                    <div id={"about—us-content"}>
                        <div style={{width:"100%",lineHeight:"50px",margin:"0 30px",fontSize:"20px",fontWeight:"bold"}}>单位简介：</div>
                        {
                            this.state.aboutUs.map((item, key) =>
                                <p key={key} className={"aboutUs-content-paragraph"} dangerouslySetInnerHTML={{ __html: item }}/>
                            )
                        }
                    </div>
                    <div id={"organization-content"}>
                        <div style={{width:"100%",lineHeight:"60px",margin:"0 30px",fontSize:"20px",fontWeight:"bold"}}>组织架构：</div>
                        <img src={"https://sso.ynmap.cn/staticSource/common/framework.png"}/>
                    </div>
                    <div id={"leadership-content"}>
                        <div style={{width:"100%",lineHeight:"50px",margin:"0 30px",fontSize:"20px",fontWeight:"bold"}}>领导信息：</div>
                        {
                            this.state.leadership.map((item, key) =>
                                <p key={key} className={"aboutUs-content-paragraph"} dangerouslySetInnerHTML={{ __html: item }}/>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}


export default AboutUs;
