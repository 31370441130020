import React from 'react';
import Title from '../title/Title';
import './ThematicChannels.css';
import { Link } from 'react-router-dom';

class ThematicChannels extends React.Component {
  constructor(props) {
    super(props);
    this.thematicData = [
      {
        title: '云南概况',
        img: '/staticImg/homePage/thematicChannels/yngk.png',
        href: 'https://app.ynmap.cn/app/yngk/',
        target: '_blank'
      },
      {
        title: '生物多样性',
        img: '/staticImg/homePage/thematicChannels/swdyxn.png',
        href: 'https://app.ynmap.cn/app/cop15/',
        target: '_blank'
      },
      {
        title: '实景三维',
        img: '/staticImg/homePage/thematicChannels/sjsw.png',
        href: 'https://app.ynmap.cn/demo/qjyn/',
        target: '_blank'
      },
      // {
      //   title: '测绘成果审批',
      //   img: '/staticImg/homePage/thematicChannels/xtspfm.png',
      //   href: 'http://182.247.253.84:18812/#/login',
      //   target: '_blank'
      // }
    ];
  }

  render() {
    return (
      <div className="thematic-channels">
        <div className="thematic-channels-container">
          <div className="thematic-channels-title">
            <Title title="专题频道" />
          </div>
          <div className="thematic-channels-content">
            {this.thematicData.map((item, index) => {
              return (
                <div className="thematic-channels-item" key={index}>
                  <a href={item.href} target={item.target}>
                    <div className="thematic-channels-item-title">
                      {item.title}
                    </div>
                    <img src={process.env.PUBLIC_URL+item.img} alt={item.title} />
                  </a>
                </div>
              );
            })}
          </div>
          {/* <div className="thematic-channels-btn">
            <Link to="/Applications">
              <button>了解更多 →</button>
            </Link>
          </div> */}
        </div>
      </div>
    );
  }
}

export default ThematicChannels;
