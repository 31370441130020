import axios from 'axios'

/** 不统一处理错误 */
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: "",
  // 超时
  timeout: 10000,
  responseType: "blob", // 文件接受使用 blob
})
// request拦截器
service.interceptors.request.use(config => {
  config.headers["Content-Type"] = "application/octet-stream;charset=utf-8"
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    if (res.status === 200){
      return res
    }else {
      return Promise.reject()
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
