import React from 'react';
import './ContactUs.css';

class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contactUs: [],
        }
    }

    componentDidMount() {
        fetch(process.env.PUBLIC_URL + '/staticData/body/support/contactUs/ContactUs.json', {
            method: 'GET'
        }).then(res => res.json()).then(data => {
            this.setState({contactUs: data.contactUs});
        })
    }

    render() {
        return (
            <div className={"contactUs-content"}>
                {
                    this.state.contactUs.map((contactUs, key) =>
                        contactUs.childs ?
                            <p key={key} className={"contactUs-content-paragraph-tow"}>
                                {contactUs.paragraph}
                            </p> :
                            <p key={key} className={"contactUs-content-paragraph"}>
                                {contactUs.paragraph}
                            </p>
                    )
                }
            </div>
        );
    }
}

export default ContactUs;
