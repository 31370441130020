import React from 'react';
import $ from 'jquery';
import './myAntdStyle.css';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect
} from 'react-router-dom';
import Header from './components/header/Header';

import Home from './components/body/homePage/Home';
import ServicesList from './components/body/services/ServicesList';
import ProduceList from './components/body/produce/ProduceList';
import Applications from './components/body/applications/Applications';
import Example from './components/body/example/Example';
import {
  getToken,
  getZWToken,
  removeToken,
  removeZWToken,
  setToken,
  setZWToken
} from './modules/CookieTools';

import DatumResource from './components/body/sources/DatumResource';
import MapResource from './components/body/sources/MapResource';
import SoftwareResource from './components/body/sources/SoftwareResource';
import YearPlan from './components/body/homePage/peopleWork/yearPlan/YearPlan';
import WaterDistribution from './components/body/homePage/peopleWork/waterDistribution/WaterDistribution';
import MineralDistribution from './components/body/homePage/peopleWork/mineralDistribution/MineralDistribution';

import './App.css';
import System from './components/config/System';
import Support from './components/body/support/Support';
import SupportInfo from './components/body/support/SupportInfo';
import AdvertisingBoard from './components/advertisingBoard/AdvertisingBoard';
import defined from './modules/defined';
import { Store } from './store/Store';
import {
  removeName,
  removeUser,
  setName,
  setUser
} from './store/reducers/UserSore';
import { getGuid } from './modules/UUIDUtils';

class App extends React.Component {
  constructor(props) {
    super(props);
    loading();
  }

  render() {
    return (
      <div className={'app-container'}>
        <Header />
        <div className={'app-homePage'}>
          <Switch>
            <Route path={'/index'} component={Home} />
            {/*支持 界面*/}
            <Route path={'/support'} component={Support} />
            <Route path={'/supportInfo'} component={SupportInfo} />

            {/*<Route path={"/user"} component={UserInfo}/>*/}
            {/*资源下载---App和标准地图*/}
            <Route path={'/MapResource'} component={MapResource} />
            <Route path={'/SoftwareResource'} component={SoftwareResource} />
            <Route path={'/DatumResource'} component={DatumResource} />
            {/*服务资源*/}
            <Route path={'/serviceList'} component={ServicesList} />
            {/*地图产品*/}
            <Route path={'/produce'} component={ProduceList} />
            {/*应用案例*/}
            <Route path={'/Applications'} component={Applications} />
            {/*API在线案例*/}
            <Route path={'/example'} component={Example} />
            {/*十四五规划*/}
            <Route path={'/yearPlan'} component={YearPlan} />
            {/*水资源分布*/}
            <Route path={'/waterDistribution'} component={WaterDistribution} />
            {/*矿产资源分布*/}
            <Route
              path={'/mineralDistribution'}
              component={MineralDistribution}
            />
            <Redirect exact form={'/'} to={'/index'} />
          </Switch>
        </div>
        <AdvertisingBoard />
      </div>
    );
  }
}

function loading() {
  //1、天地图--判断token是否存在--存在表示已经登录
  if (defined(getToken())) {
    tdtynViewUserInfo();
  } else if (defined(getZWToken())) {
    //2、政务网--判断token是否存在--存在表示已经登录
    zwViewUserInfo();
  } else {
    //3、token不存在---未登录
    const searchParams = new URLSearchParams(window.location.search);
    let accessLink = searchParams.get('accessLink');
    let refer = searchParams.get('refer');
    let status = searchParams.get('status');
    let token = searchParams.get('token');

    //3.1 判断是否携带参数--携带status、token--天地图--主动登录
    if (defined(status) && status + '' === '0' && defined(token)) {
      setToken(token);
      tdtynViewUserInfo();
    } else if (defined(accessLink) && defined(refer)) {
      //3.2 判断是否携带参数--accessLink、refer--政务网--主动登录
      let url =
        System.gateway +
        '/yntdtsite/ynzwsso/login?accessLink=' +
        accessLink +
        '&refer=' +
        refer;
      $.ajax({
        url: url,
        type: 'GET',
        async: false,
        success: function (data) {
          setZWToken(data);
          zwViewUserInfo();
        },
        error: function (err) {
          console.log(err);
        }
      });
    } else {
      //3.3 判断是否携带参数--未携带参数
      // 3.3.1 未携带参数--天地图--查看是否被动登录 (政务网不考虑被动登录)
      verifyTiandituLogin();
    }
  }
}

/**   校验天地图是否登录  */
function verifyTiandituLogin() {
  let guid = getGuid();
  let urlParam =
    System.gateway +
    '/auth/tianditu/casVerify' +
    '?callback=callback' +
    '&sAppId=' +
    System.sAppId +
    '&guid=' +
    guid;

  //验证是否登录
  $.ajax({
    type: 'get',
    async: false,
    url: urlParam,
    dataType: 'jsonp',
    jsonp: 'callback',
    jsonpCallback: 'formLogin',
    complete() {
      // 请求完成后去本地查看是否登录【尝试获取token】
      let urlParamT =
        System.gateway +
        '/auth/tianditu/casToken?sAppId=' +
        System.sAppId +
        '&guid=' +
        guid;
      $.ajax({
        url: urlParamT,
        type: 'GET',
        async: false,
        success(res) {
          if (res.code === 200 && defined(res.token)) {
            setToken(res.token);
            tdtynViewUserInfo();
          } else {
            removeToken();
            Store.dispatch(removeUser());
            Store.dispatch(removeName());
          }
        }
      });
    }
  });
}

//获取政务用户信息
function zwViewUserInfo() {
  let userName = Store.getState().user.userName;
  if (defined(userName)) {
    return;
  }
  let url =
    System.gateway +
    '/yntdtsite/ynzwsso/getUserInfo?token=' +
    getZWToken() +
    '&refer=NA';
  $.ajax({
    url: url,
    type: 'GET',
    dataType: 'json',
    async: false,
    success: function (data) {
      if (data == null || data === 'null') {
        removeZWToken();
        Store.dispatch(removeUser());
        Store.dispatch(removeName());
      } else {
        Store.dispatch(setUser(data.userInfo));
        Store.dispatch(setName(data.userInfo.userName));
      }
    },
    error: function (err) {
      removeZWToken();
      Store.dispatch(removeUser());
      Store.dispatch(removeName());
    }
  });
}

//获取天地图用户信息
function tdtynViewUserInfo() {
  let userName = Store.getState().user.userName;
  if (defined(userName)) {
    return;
  }

  $.ajax({
    type: 'get',
    async: false,
    url: System.gateway + '/auth/tianditu/casInfo',
    beforeSend: function (request) {
      request.setRequestHeader('Tdt-S-Token', getToken());
      request.setRequestHeader('Tdt-S-App-Id', System.sAppId);
    },
    success: function (res) {
      if (res.code === 200) {
        Store.dispatch(setUser(res.data));
        Store.dispatch(setName(res.data.userEmail));
      } else {
        removeToken();
        Store.dispatch(removeUser());
        Store.dispatch(removeName());
      }
    },
    error: function (err) {
      removeToken();
      Store.dispatch(removeUser());
      Store.dispatch(removeName());
    }
  });
}

export default App;
