import React from 'react'
import System from "../../../config/System";
import './SuggestFeedbackInfo.css';
import {Avatar, Button, Col, Comment, Divider, Image, List, Row, Steps, Tag, Form, Input, Empty, Tooltip} from "antd";
import {CommentOutlined, EyeOutlined, UserOutlined,LikeOutlined} from "@ant-design/icons";
import DislikeOutlined from "@ant-design/icons/lib/icons/DislikeOutlined";
import {getToken, getZWToken} from "../../../../modules/CookieTools";
import defined from "../../../../modules/defined";
import {Store} from "../../../../store/Store";

// 步骤条
const { Step } = Steps;
const { TextArea } = Input;
// 评论回复编辑框
const Editor = ({ onChange, onSubmit, submitting, value }) => (
    <>
        <Form.Item>
            <TextArea rows={4} onChange={onChange} value={value} placeholder="请输入评论"/>
        </Form.Item>
        <Form.Item>
            <Button style={{float: "right",marginRight: 26}} htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                添加评论
            </Button>
        </Form.Item>
    </>
);
// 评论列表
const CommentList = ({ comments }) => (
    <List
        dataSource={comments}
        itemLayout="horizontal"
        renderItem={props => <Comment {...props} />}
    />
);

class SuggestFeedbackInfo extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            gbookData: {
                createBy: null,
                createTime: null,
                creationContent: null,
                creationEmail: null,
                creationImg: null,
                creationName: null,
                creationPhone: null,
                creationTime: null,
                creationTitle: null,
                dataStatus: null,
                deleteFlag: null,
                id: null,
                remarks: null,
                replyDate: null,
                replyText: null,
                replyer: null,
                siteId: null,
                state: null,
                updateBy: null,
                updateTime: null,
                creationType: null,
                viewCount: null,
                deviceType: null,
                tdtGbookReplyList:[{
                    commentId:null,
                    gbookId:null,
                    commentReplier:null,
                    commentText:null,
                    commentDate:null,
                    likeCount:null,
                }]
            },
            // 提交延迟状态
            submitting: false,
            // 评论框输入值
            value: '',
            // 评论内容
            comments: [],
            // 点赞行为
            likeAction: null,
            // 标签颜色
            tagColor: null,
            // 登录状态
            loginState: false,

            userName: null
        }
    }

    componentDidMount() {
        // 请求单页数据并存储到state
        const gbookId = this.props.match.params.param;
        this.setState({id: gbookId});
        this.queryGbook(gbookId);

        // 登录状态
        let userName = Store.getState().user.userName
        if (defined(userName) && defined(getToken())){ //天地图登录
            this.setState({loginState: true});
            this.setState({userName: userName});
        }else if (defined(userName) && defined(getZWToken())){ //政务网登录
            this.setState({loginState: true});
            this.setState({userName: userName});
        }else { //没有登录
            this.setState({loginState: false});
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.param !== this.state.id) {
            this.setState({ id: nextProps.match.params.param},() => {
                //当路由参数改变时，重新请求接口获取数据
                this.queryGbook(nextProps.match.params.param);
            });
        }
    }

    render() {
        return (
            <div className={"suggestFeedbackInfo-centre"}>
                <div className={"suggestFeedbackInfo-status"}>
                    <Steps size="small"  current={this.getStepByState(this.state.gbookData.state)}>
                        <Step title="正在审核" />
                        <Step title="审核通过" />
                        <Step title="已采纳" />
                    </Steps>
                </div>
                <div className={"suggestFeedbackInfo-title"}>
                    <div className={"suggestFeedbackInfo-title-name"}>
                        {this.state.gbookData.creationTitle}
                    </div>
                    <div className={"suggestFeedbackInfo-title-status"}>
                        {this.getStateName(this.state.gbookData.state)}
                    </div>
                </div>
                <Row>
                    <Col flex="70px">
                        {this.state.gbookData.creationType===null?(
                            <Tag style={{fontSize:'  14px'}}>{"暂无分类"}</Tag>
                        ):(
                            <Tag style={{fontSize:'14px'}}>{this.state.gbookData.creationType}</Tag>
                        )}
                    </Col>
                    <Col flex="70px">
                        {<Tag>{this.state.gbookData.deviceType}</Tag>}
                    </Col>
                </Row>


                <div key={Math.random()} className={"suggestFeedbackInfo-detail-block"}>
                    <div className={"suggestFeedbackInfo-detail-block-content"}>
                        {this.state.gbookData.creationContent}
                    </div>
                    <Image.PreviewGroup>
                        {this.state.gbookData.creationImg === null||undefined ? (
                            <div/>
                        ) : (
                            this.state.gbookData.creationImg.split(",").map((item, index) =>{
                                return(
                                    <div key={index} className={"suggestFeedbackInfo-detail-block-content-img"}>
                                        <Image height={240} src={System.staticSourceUrl + item}/>
                                    </div>
                                )
                            })
                        )}
                    </Image.PreviewGroup>
                </div>
                <div className={"suggestFeedbackInfo-detail-block-replier"}>
                    <Avatar className={"suggestFeedbackInfo-detail-block-replier-avatar"} icon={<UserOutlined />} />
                    <span className={"suggestFeedbackInfo-detail-block-replier-creationName"}>{this.limitName(this.state.gbookData.creationName)}</span>
                    <span className={"suggestFeedbackInfo-detail-block-replier-creationTime"}>
                            {this.state.gbookData.creationTime !== null && this.state.gbookData.creationTime !== "" ? "发布于   " +  this.state.gbookData.creationTime : ""}
                        </span>
                    <span className={"suggestFeedbackInfo-detail-block-replier-icon"}>
                            <EyeOutlined/>
                            <span className={"suggestFeedbackInfo-detail-block-replier-icon-1"}>{this.state.gbookData.viewCount}</span>
                            <CommentOutlined/>
                            <span className={"suggestFeedbackInfo-detail-block-replier-icon-1"}>{this.state.gbookData.tdtGbookReplyList.length}</span>
                        </span>
                </div>
                <Row className={"suggestFeedbackInfo-reply-btn"}>
                    {/*<a href="#comment">
                        <Button type="primary">发表评论</Button>
                    </a>*/}
                </Row>
                <div>
                    <h3 className={"suggestFeedbackInfo-reply-title"}>{"全部回复("+this.state.gbookData.tdtGbookReplyList.length+")"}</h3>
                    {this.state.gbookData.tdtGbookReplyList.length === 0 ?(
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"暂无评论"}/>
                    ):(
                        <CommentList comments={this.state.comments} />
                    )}
                    {/*{this.state.loginState?(
                        // 判断是否登录，已登录则可以进行评论
                        <Comment
                            id={"comment"}
                            avatar={<Avatar icon={<UserOutlined />} />}
                            content={
                                <Editor
                                    onChange={this.onCommentChange}
                                    onSubmit={this.onCommentSubmit}
                                    submitting={this.state.submitting}
                                    value={this.state.value}
                                />
                            }
                        />
                    ):(
                        // 判断是否登录，未登录则可跳转登陆界面
                        <div>
                            <div className={"comment-reply-withoutLogin"}>
                                温馨提示： 请先您登录,再评论，感谢您的关注与支持
                            </div>
                            <Button disabled style={{float: "right",marginBottom: 20,marginRight: 26}}>
                                添加评论
                            </Button>
                        </div>
                    )}*/}
                </div>
            </div>
        );
    }

    limitName(value){
        if(value !== null || undefined){
            if(value === "云南省地图院天地图事业部"){
                return "云南省地图院天地图事业部";
            }
            value = value.replace(/\s+/g,"");
            let strTemp = value.substring(0,2);
            for (let i = 1; i < value.length; i++) {
                strTemp += "*"
            }
            return strTemp;
        }
    }

    //查询留言反馈
    queryGbook(id){
        // 请求单条数据
        fetch(System.gateway + '/tdtyns/TdtGbookNew/' + id,{
            method:'GET'
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询留言失败");
                return;
            }
            // GbookData赋值
            let newGbookData = null;
            for(let key in data.data){
                newGbookData = Object.assign(this.state.gbookData,{[key]:data.data[key]});
            }
            // 加载评论内容列表及样式
            let ReplyList = [];
            if(newGbookData.tdtGbookReplyList.length !== 0){
                for(let i = 0; i < newGbookData.tdtGbookReplyList.length; i++){
                    let tdtGbookReplyStyle = {
                        actions: [(
                                <span>
                                    <span onClick={this.handleLike(newGbookData.tdtGbookReplyList[i].commentId)} className={"comment-reply-click-like"}/>
                                    <span className="comment-action">{newGbookData.tdtGbookReplyList[i].likeCount}</span>
                                </span>
                            ),
                            (
                                <span>
                                    <DislikeOutlined style={{marginRight: 13, position: "relative",bottom: 15}}/>
                                    <span className="comment-action">{"0"}</span>
                                </span>
                            )],
                        author: this.limitName(newGbookData.tdtGbookReplyList[i].commentReplier),
                        avatar: (<Avatar icon={<UserOutlined />} />),
                        content: (
                            <p className={"comment-action-value"}>
                                {newGbookData.tdtGbookReplyList[i].commentText}
                            </p>
                        ),
                        datetime: (
                            <span>{newGbookData.tdtGbookReplyList[i].commentDate}</span>
                        ),
                    }
                    ReplyList.push(tdtGbookReplyStyle);
                }
            }
            this.setState({
                gbookData : newGbookData,
                comments : ReplyList
            });
        });
    }

    //点赞按钮操作
    handleLike = (id) => {
        return (e)=>{
            let param = {
                likeCount : this.state.gbookData.tdtGbookReplyList.find(item => item.commentId === id).likeCount + 1,
                commentId: id
            }
            //点赞接口
            fetch(System.gateway + '/tdtyns/TdtGbookNew/like', {
                method:'PUT',
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify(param)
            }).then(res => res.json()).then(data => {
                if (data.code !== 200) {
                    alert("点赞失败");
                    return;
                }
                this.queryGbook(this.state.gbookData.id);
            });
        }
    };

    //标签状态数据显示
    getStateName(state){
        switch (state) {
            case "0":
                return(<Tag color={"blue"}>{"正在审核"}</Tag>);
            case "1":
                return(<Tag color={"green"}>{"审核通过"}</Tag>);
            case "2":
                return(<Tag color={"cyan"}>{"已采纳"}</Tag>);
            case "3":
                return(<Tag color={"red"}>{"未通过审核"}</Tag>);
        }
    }

    //步骤条状态切换
    getStepByState(state){
        switch (state) {
            case "0":
                return 0;
            case "1":
                return 1;
            case "2":
                return 2;
        }
    }

    //评论数据提交
    onCommentSubmit = () => {
        let params = {
            gbookId: this.state.gbookData.id,
            commentText: this.state.value,
            commentReplier: this.state.userName
        }
        if (!this.state.value) {
            return;
        }
        fetch(System.gateway + '/tdtyns/TdtGbookNew/addReply', {
            method:'POST',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(params)
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert(data.code + "留言失败");
                return;
            }
            //延迟提交
            this.setState({submitting: true});
            setTimeout(() => {
                this.setState({
                    submitting: false,
                    value: '',
                });
                //评论后重新请求数据
                this.queryGbook(this.state.gbookData.id);
            }, 500);// 请求单条数据
        });
    };

    //评论框内容改变
    onCommentChange = e => {
        this.setState({value: e.target.value});
    };

}

export default SuggestFeedbackInfo
