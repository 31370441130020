import React from 'react';
import './DeveloperPanel.css';
import Title from '../title/Title';
import System from '../../../config/System';

class DeveloperPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      currentApi: null,
      becomeDeveloper: null,
      learnMore: null,
      developerDatas: []
    };
  }

  componentDidMount() {
    fetch(
      process.env.PUBLIC_URL +
      '/staticData/homePage/developerPanel/DeveloperPanel.json',
      { method: 'GET' }
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          title: data.title,
          currentApi: data.items[0],
          becomeDeveloper: data.becomeDeveloper,
          learnMore: data.learnMore,
          developerDatas: data.items
        });
      });
  }

  mebuClick(data) {
    if (data.name == "二三维 API") {
      return;
    }
    else {
      let newData = null;
      this.state.developerDatas.forEach((item) => {
        if (item.name === data.name) {
          newData = item;
        }
      });
      this.setState({ ...this.state, currentApi: newData });
    }
  }

  render() {
    return (
      <div className={'developer-panel'}>
        <div className="developer-panel-container">
          <div className="developer-paneltitle">
            <Title title={this.state.title} />
          </div>
          <div className="developer-panel-content">
            <div className="developer-panel-content-menu">
              {this.state.developerDatas.map((item, index) => (
                <div
                  key={index}
                  className={` developer-panel-content-menu-item ${this.state.currentApi.name === item.name ? 'active' : ''
                    }`}
                  onClick={this.mebuClick.bind(this, item)}
                >
                  <div className=" developer-panel-content-menu-item-title">
                    {item.name}
                  </div>
                  <img
                    className=" developer-panel-content-menu-item-icon"
                    src={process.env.PUBLIC_URL + item.icon}
                    alt=""
                  />
                  <a href={item.href} target={item.target} className={item.className}>
                  </a>
                </div>
              ))}
              <a
                href={this.state.becomeDeveloper?.href || ''}
                target={this.state.becomeDeveloper?.target}
                className="developer-panel-content-menu-link"
              >
                {this.state.becomeDeveloper?.name}
              </a>
              <a
                href={System.publishSite + '/control/'}
                target={this.state.becomeDeveloper?.target}
                className="developer-panel-content-menu-link"
              >
                本地资源申请 →
              </a>
            </div>
            <div className="developer-panel-content-list">
              {this.state.currentApi?.childs?.map((item, index) => (
                <div key={index} className="developer-panel-content-list-item">
                  <a href={item.href} target={item.target}>
                    <div className="developer-panel-content-list-item-img">
                      <img src={process.env.PUBLIC_URL + item.img} alt="" />
                    </div>
                    <div className="developer-panel-content-list-item-title">
                      {item.name}
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="developer-panel-content-more">
            <a
              href={this.state.learnMore?.href || ''}
              target={this.state.learnMore?.target}
            >
              <button>{this.state.learnMore?.name}</button>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default DeveloperPanel;
