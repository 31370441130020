import React from 'react';
import Search from '../../common/search/Search';
import PagingButton from '../../common/paging/PagingButton';
import './MapResource.css';
import QRCode from 'qrcode.react';
import System from "../../config/System";
import Footer from "../../footer/Footer";
import './SourcesList.css';
import {Link} from "react-router-dom";
import downloadRequest from "../../../modules/downloadRequest";
import defined from "../../../modules/defined";

class MapResource extends React.Component {
    constructor(props) {
        super(props);
        this.sourceClassifyHiddenClass = "sources-list-classify-hidden";
        this.sourceClassifyShowClass = "sources-list-classify-show";

        this.selectedClass = "mapResource-list-select-serviceType-selected";
        this.unselectedClass = "mapResource-list-select-serviceType-unselected";

        this.showPopupClass = "mapResource-popupShow";
        this.hiddenPopupClass = "mapResource-popupHidden";

        this.mapResourceShowClass = "mapResource-list-vessel-show";
        this.mapResourceHiddenClass = "mapResource-list-vessel-hidden";

        this.state = {
            sourceClassifyClass: this.sourceClassifyShowClass,
            title: '',
            resourceType:[],
            mapResourceClass: this.mapResourceShowClass,
            popupClass: this.hiddenPopupClass,
            service_type_name: "地图类型：",
            service_type: [],

            coverage_area_name: "覆盖范围：",
            coverage_area: [],

            times_name: "地图版本：",
            times: [],
            resourceList: [],

            popupTitle: "",
            popupJpg_url: "",
            popupEps_url: "",
            dataId:"",
        };

        this.param = {
            currentX: 0,
            currentY: 0,
            left: 0,
            top: 0,
            zoomVal:1
        };

        this.isMouseDown = false;

        this.activeTypeIndex = 0;
        this.activeType = false;
        this.activeCoverageIndex = 0;
        this.activeCoverage = false;
        this.activeYearIndex = 0;
        this.activeYear = false;

        this.queryParam = {
            serviceType: null,
            coverageArea: null,
            releaseTime: null,
            keyword:null
        }
    }

    componentDidMount() {
        fetch(process.env.PUBLIC_URL + '/staticData/body/sources/ResourceType.json', {
            method: 'GET'
        }).then(res => res.json()).then(data => {
            this.setState({resourceType: data.items,title:data.title});
        });
        //获取主体分类
        fetch(System.gateway + '/tdtyns/TdtMapResourceType/list?delFlag=1&siteId=' + System.siteValue, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("主题分类查询失败");
                return;
            }
            this.setState({service_type: data.rows});
        });

        //获取行政区划
        fetch(System.gateway + '/tdtyns/SysAdministrativeRegion/list?delFlag=1', {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("行政区划查询失败");
                return;
            }
            //过滤 市级和省
            let temps = [];
            for (let item of data.data) {
                if (item.regionRank === "0" || item.regionRank === "1") {
                    temps.push(item)
                }
            }
            this.setState({coverage_area: temps});
        });

        fetch(System.gateway + '/tdtyns/TdtMapResource/getYearList?siteId=' + System.siteValue, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("年份查询失败");
                return;
            }
            if (data.data.length !== 0) {
                let rows = data.data.sort().reverse();
                this.setState({times: rows})
            }
        });
    }

    //选择类型
    serviceTypeSelectedClick(item,key, target) {
        if (this.activeTypeIndex === key) {
            if (this.activeType) {
                this.setState({["map_type_color_" + key]: this.unselectedClass});
                this.queryParam.serviceType = null;
                this.activeType = false;
                this.activeTypeIndex = key;
            }else {
                this.setState({["map_type_color_" + key]: this.selectedClass});
                this.queryParam.serviceType = item.id;
                this.activeType = true;
                this.activeTypeIndex = key;
            }
        }else {
            this.setState({["map_type_color_" + this.activeTypeIndex]: this.unselectedClass});
            this.setState({["map_type_color_" + key]: this.selectedClass});
            this.queryParam.serviceType = item.id;
            this.activeType = true;
            this.activeTypeIndex = key;
        }
        this.queryParam.keyword = null;

        //根据条件查询
        this.getPageAmount();
    };

    //选择范围
    coverageAreaSelectedClick(item,key, target) {
        if (this.activeCoverageIndex === key) {
            if (this.activeCoverage) {
                this.setState({["coverage_area_color_" + key]: this.unselectedClass});
                this.queryParam.coverageArea = null;
                this.activeCoverage = false;
                this.activeCoverageIndex = key;
            }else {
                this.setState({["coverage_area_color_" + key]: this.selectedClass});
                this.queryParam.coverageArea = item.id;
                this.activeCoverage = true;
                this.activeCoverageIndex = key;
            }
        }else {
            this.setState({["coverage_area_color_" + this.activeCoverageIndex]: this.unselectedClass});
            this.setState({["coverage_area_color_" + key]: this.selectedClass});
            this.queryParam.coverageArea = item.id;
            this.activeCoverage = true;
            this.activeCoverageIndex = key;
        }
        this.queryParam.keyword = null;

        //根据条件查询
        this.getPageAmount();
    };

    //选择年份
    yearSelectedClick(item,key, target) {
        if (this.activeYearIndex === key) {
            if (this.activeYear) {
                this.setState({["times_color_" + key]: this.unselectedClass});
                this.queryParam.releaseTime = null;
                this.activeYear = false;
                this.activeYearIndex = key;
            }else {
                this.setState({["times_color_" + key]: this.selectedClass});
                this.queryParam.releaseTime = item + "-1-1";
                this.activeYear = true;
                this.activeYearIndex = key;
            }
        }else {
            this.setState({["times_color_" + this.activeYearIndex]: this.unselectedClass});
            this.setState({["times_color_" + key]: this.selectedClass});
            this.queryParam.releaseTime = item + "-1-1";
            this.activeYear = true;
            this.activeYearIndex = key;
        }
        this.queryParam.keyword = null;
        //根据条件查询
        this.getPageAmount();
    };

    // 查询总页码---加载查询列表--test
    getPageAmount() {
        let url = System.gateway + '/tdtyns/TdtMapResource/getPageTotal?delFlag=1&siteId=' + System.siteValue;
        if (this.queryParam.serviceType !== null) {
            url = url + '&serviceType=' + this.queryParam.serviceType;
        }
        if (this.queryParam.coverageArea !== null) {
            url = url + '&coverageArea=' + this.queryParam.coverageArea
        }
        if (this.queryParam.releaseTime !== null) {
            url = url + '&releaseTime=' + this.queryParam.releaseTime
        }
        if (this.queryParam.keyword !== null) {
            url = url + '&keyword=' + this.queryParam.keyword
        }

        fetch(url, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询数据失败");
                return;
            }
            let amountPage = Math.floor((data.data-1) / 10) + 1;
            this.child.setAmountPage(amountPage);
        });
    }

    //查询数据---分页
    pagingQuery(activePage, pageSize) {
        let url = System.gateway + '/tdtyns/TdtMapResource/list?pageNum='+activePage+'&pageSize='+pageSize+'&delFlag=1&siteId=' + System.siteValue;
        if (this.queryParam.serviceType !== null) {
            url = url + '&serviceType=' + this.queryParam.serviceType;
        }
        if (this.queryParam.coverageArea !== null) {
            url = url + '&coverageArea=' + this.queryParam.coverageArea
        }
        if (this.queryParam.releaseTime !== null) {
            url = url + '&releaseTime=' + this.queryParam.releaseTime
        }
        if (this.queryParam.keyword !== null) {
            url = url + '&keyword=' + this.queryParam.keyword
        }
        fetch(url, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询数据失败");
                return;
            }
            console.log("resourceList===",data.rows);
            this.setState({resourceList: data.rows});
            this.childSearch.startSearch(false)
        });
    }
    // 隐藏分类
    hiddenClassifyVessel(){
        this.setState({sourceClassifyClass: this.sourceClassifyHiddenClass})
    }
    // 显示分类
    showClassifyVessel(){
        this.setState({sourceClassifyClass: this.sourceClassifyShowClass})
    }
    render() {
        return (
            <div className={'sources-list-container'}>
                <div className={this.state.sourceClassifyClass}>
                    <div className={"sources-list-classify-vessel"}>
                        <div className={"sources-list-classify-title"}>
                            <span>{this.state.title}</span>
                        </div>
                        <div id={"sources-list-classify-con"} className={"sources-list-classify-con"}>
                            {
                                this.state.resourceType.map((item, key) =>
                                    <Link style={{background:key===0?"#f39800":false}} key={key} to={item.href}>
                                        {item.name}
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div id={"sources-list-content"} className={"sources-list-content"}>
                    <div className={'mapResource-list'}>
                        <div className={'mapResource-list-title'}>
                            {"资源下载 > 地图"}
                        </div>
                        <div className={this.state.mapResourceClass}>
                            <div className={'mapResource-list-select'}>
                                <div className={"mapResource-list-select-serviceType-line"}>
                                    <div className={"mapResource-list-select-serviceType"}>
                                <span className={"mapResource-list-select-serviceType-name"}>
                                    {this.state.service_type_name}
                                </span>
                                    </div>
                                    <div className={"mapResource-list-select-serviceType-con"}>
                                        {
                                            this.state.service_type.map((item, key) =>
                                                    <span onClick={this.serviceTypeSelectedClick.bind(this, item,key)}
                                                          className={this.state["map_type_color_" + key]===undefined?this.unselectedClass:this.state["map_type_color_" + key]} key={key}>
                                        {
                                            item.typeName
                                        }
                                    </span>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={"mapResource-list-select-serviceType-line"}>
                                    <div className={"mapResource-list-select-serviceType"}>
                                <span className={"mapResource-list-select-serviceType-name"}>
                                    {this.state.coverage_area_name}
                                </span>
                                    </div>
                                    <div className={"mapResource-list-select-serviceType-con"}>
                                        {
                                            this.state.coverage_area.map((item, key) =>
                                                    <span onClick={this.coverageAreaSelectedClick.bind(this, item,key)}
                                                          className={this.state["coverage_area_color_" + key] === undefined ? this.unselectedClass : this.state["coverage_area_color_" + key]}
                                                          key={key}>
                                        {
                                            item.regionAlias
                                        }
                                    </span>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={"mapResource-list-select-serviceType-line"}>
                                    <div className={"mapResource-list-select-serviceType"}>
                                <span className={"mapResource-list-select-serviceType-name"}>
                                    {this.state.times_name}
                                </span>
                                    </div>
                                    <div className={"mapResource-list-select-serviceType-con"}>
                                        {
                                            this.state.times.map((item, key) =>
                                                    <span onClick={this.yearSelectedClick.bind(this, item,key)}
                                                          className={this.state["times_color_" + key]===undefined?this.unselectedClass:this.state["times_color_" + key]} key={key}>
                                        {item + " 版"}
                                    </span>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className={'mapResource-list-search'}>
                                <Search search={this.searchClick} onRef={(ref) => {this.childSearch = ref;}}/>
                            </div>
                            <div className={'mapResource-list-content'}>
                                <div className={'mapResource-list-content-list-items'}>
                                    {
                                        this.state.resourceList.map((item, key) =>
                                            <div className={"mapResource-list-content-list-item"} key={key}>
                                                <div className="mapResource-list-content-list-item-title">
                                                    <p className="title">{item.mapTitle}</p>
                                                    <span onClick={this.lookOver.bind(this, item)}
                                                          className="detail">查看&gt;&gt;</span>
                                                </div>
                                                <div className={"mapResource-list-content-list-item-con"}>
                                                    <img onClick={this.lookOver.bind(this, item)} src={System.staticSourceUrl + item.titleImg}/>
                                                    <div className={"mapResource-list-content-list-item-con-des"}>
                                                        <div className={"mapResource-list-content-list-item-con-des_"}>
                                                            <span>地图版本：</span>
                                                            <p>{item.releaseTime.slice(0,4) + ' 版'}</p>
                                                        </div>
                                                        <div className={"mapResource-list-content-list-item-con-des_"}>
                                                            <span>摘要信息：</span>
                                                            <p>{item.mapAbstract}</p>
                                                        </div>
                                                        <div className={"mapResource-list-content-list-item-con-des_"}>
                                                            <span>关键字：</span>
                                                            <div className={"mapResource-list-content-list-item-con-des_a"}>
                                                                {
                                                                    item.keyword.split(/[,| ，]/g).map((itemT, index) =>
                                                                        itemT !=="" && itemT!==null?<span key={index}>{itemT}</span>:false
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className={"mapResource-list-content-list-item-con-des_"}>
                                                            <span>审图号：</span>
                                                            <p>{item.mapCartographic}</p>
                                                        </div>
                                                    </div>
                                                    <div className={"mapResource-list-content-list-item-con-show"}>
                                                        <div className={"mapResource-list-content-list-item-con-show-QR"}>
                                                            <div className={"mapResource-list-content-list-item-con-show-QR_"}>
                                                                扫<br/>码<br/>预<br/>览
                                                            </div>
                                                            <div className={"mapResource-list-content-list-item-con-show-QR_a"}>
                                                                <QRCode
                                                                    value={System.staticSourceUrl + item.jpgUrl}// 生成二维码的内容
                                                                    size={95} // 二维码的大小
                                                                    fgColor="#000000" // 二维码的颜色
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className={"mapResource-list-content-list-item-con-show-count"}>
                                                            <div title={"查看量"}
                                                                 className={"mapResource-list-content-list-item-con-show-count_"}>
                                                                <i className={"iconfont"}>&#xe601;</i>
                                                                <span>{item.viewCount}</span>
                                                            </div>
                                                            <div title={"下载量"}
                                                                 className={"mapResource-list-content-list-item-con-show-count_"}>
                                                                <i className={"iconfont"}>&#xe6cd;</i>
                                                                <span>{item.downloadCount}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div id={"mapResource-list-content-pagingButton"}
                                     className={'mapResource-list-content-pagingButton'}>
                                    <PagingButton pagingQuery={this.pagingQuery.bind(this)}
                                                  getPageAmount={this.getPageAmount.bind(this)} onRef={(ref) => {this.child = ref;}}/>
                                </div>
                            </div>
                        </div>

                        {/*弹窗*/}
                        <div className={this.state.popupClass}>
                            <div className={"mapResource-popup-title"}>
                                <div>{this.state.popupTitle}</div>
                                <i onClick={this.closePopup.bind(this)} className={"iconfont"}>&#xe607;</i>
                            </div>
                            <div className={"mapResource-popup-Vessel"}>
                                <div className={"mapResource-popup-con-but"}>
                                    {/*<a href={System.gateway + "/common/download/tdtresource?name="+ this.state.popupJpg_url.replaceAll(/\\/g,'/') +"&id=" + this.state.dataId+"&downType="+1} className={"mapResource-popup-but-downloadJpg"}>*/}
                                    <a onClick={this.jpgDownloadData.bind(this,this.state.popupJpg_url,this.state.dataId)} className={"mapResource-popup-but-downloadJpg"}>
                                        <div>jpg下载</div>
                                        <i className={"iconfont"}>&#xe62b;</i>
                                    </a>
                                    {/*<a href={System.gateway + "/common/download/tdtresource?name="+ this.state.popupEps_url.replaceAll(/\\/g,'/') +"&id=" + this.state.dataId+"&downType="+1} className={"mapResource-popup-but-downloadJpg"}>*/}
                                    {
                                        defined(this.state.popupEps_url)?
                                        <a onClick={this.jpgDownloadData.bind(this, this.state.popupEps_url, this.state.dataId)}
                                           className={"mapResource-popup-but-downloadJpg"}>
                                            <div>eps下载</div>
                                            <i className={"iconfont"}>&#xe62b;</i>
                                        </a>:false
                                    }
                                </div>
                                <div className={"mapResource-popup-con-img-vessel"}>
                                    <div onContextMenu = {(e)=> {e.preventDefault();return false;}} className={"mapResource-popup-con-img"}>{
                                        this.state.popupJpg_url!==null&&this.state.popupJpg_url!==""&&this.state.popupJpg_url!==undefined?
                                            <img style={{top:this.param.top,left:this.param.left,transform:"scale(" + this.param.zoomVal + ")"}} onMouseOver={this.imageMouseOver.bind(this)}
                                                  src={System.staticSourceUrl + this.state.popupJpg_url}/>:false
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer version={0}/>
            </div>
        );
    }
    //下载
    jpgDownloadData(popupJpg_url,dataId,target){
        popupJpg_url = popupJpg_url.replace(/\\/g,"/");
        let url = System.gateway + "/common/download/tdtresource?name=" + popupJpg_url;
        let arr = popupJpg_url.split("/");
        this.download(url,arr[arr.length -1])

        let download = System.gateway + "/tdtyns/TdtMapResource/getDownCount?id=" + dataId;
        fetch(download, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("下载次数统计失败");
                return;
            }
            console.log("下载次数统计成功");
        });
    }

    // 测试下载
    download(urlT,name){
        // 分片下载
        downloadRequest({
            url: urlT,
            method: 'get'
        }).then((res)=>{
            let resBlob = res.data
            let url = window.URL.createObjectURL(resBlob); // 将获取的文件转化为blob格式
            let a = document.createElement("a"); // 此处向下是打开一个储存位置
            a.style.display = "none";
            a.href = url;
            a.setAttribute("download", name);
            document.body.appendChild(a);
            a.click(); //点击下载
            document.body.removeChild(a); // 下载完成移除元素
            window.URL.revokeObjectURL(url); // 释放掉blob对象
        })
    }

    //取消所有选择
    cancelSelectAll(){
        this.setState({["map_type_color_" + this.activeTypeIndex]: this.unselectedClass});
        this.queryParam.serviceThemeType = null;
        this.activeType = false;
        this.activeTypeIndex = 0;

        this.setState({["coverage_area_color_" + this.activeCoverageIndex]: this.unselectedClass});
        this.queryParam.coverageArea = null;
        this.activeCoverage = false;
        this.activeCoverageIndex = 0;

        this.setState({["times_color_" + this.activeYearIndex]: this.unselectedClass});
        this.queryParam.releaseTime = null;
        this.activeYear = false;
        this.activeYearIndex = 0;

        this.queryParam.keyword = null;
    }

    // 搜索-------功能---函数---分页===================
    searchClick = (searchIn) => {
        this.cancelSelectAll();
        this.queryParam.keyword = searchIn;
        this.getPageAmount();
    };


    //查看按钮
    lookOver(item, target) {
        this.hiddenClassifyVessel();
        this.setState({mapResourceClass: this.mapResourceHiddenClass});
        this.setState({popupClass: this.showPopupClass});
        this.setState({popupTitle: item.mapTitle});
        this.setState({popupEps_url: item.epsUrl});
        this.setState({popupJpg_url: item.jpgUrl});
        this.setState({dataId: item.id});

        this.param = {//重置参数
            currentX: 0,
            currentY: 0,
            left: 0,
            top: 0,
            zoomVal:1
        };
        let url = System.gateway + '/tdtyns/TdtMapResource/getViewCount?id=' + item.id;
        fetch(url, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查看次数统计失败");
                return;
            }
            console.log("查看次数统计成功");
        });
    }
    //关闭弹窗按钮
    closePopup(target){
        this.showClassifyVessel();
        this.setState({mapResourceClass: this.mapResourceShowClass});
        this.setState({popupClass: this.hiddenPopupClass,popupTitle: ""});
        this.setState({popupJpg_url: "",popupEps_url: "",dataId:""});
    }

    //图片拖动，放大缩小
    imageMouseOver(target){
        let that = this;
        let element = target.currentTarget;

        //放大缩小
        element.addEventListener("mousewheel",function (e) {
            e.preventDefault();
            e.wheelDelta > 0 ? element.style.cursor = "zoom-in" : element.style.cursor = "zoom-out";
            that.param.zoomVal = (that.param.zoomVal + e.wheelDelta / 1200) < 1 ? 1 : that.param.zoomVal + e.wheelDelta / 1200;
            element.style.transform = "scale(" + that.param.zoomVal + ")";
        });
        element.addEventListener("mousedown",function (e) {
            e.preventDefault();
            that.isMouseDown = true;
            that.param.currentX = e.clientX;
            that.param.currentY = e.clientY;
        });
        element.addEventListener("mouseup",function (e) {
            e.preventDefault();
            that.isMouseDown = false;
            that.startX = 0;
            that.startY = 0;
            setImgLocation();
        });

        element.addEventListener("mouseleave",function (e) {
            e.preventDefault();
            that.isMouseDown = false;
            that.startX = 0;
            that.startY = 0;
            setImgLocation();
        });
        element.addEventListener("mousemove",function (e) {
            element.style.cursor = "pointer";
            e.preventDefault();
            targetMove(e);
        });

        //图片移动
        function targetMove(e) {
            if (!that.isMouseDown) {
                return;
            }
            let nowX = e.clientX,
                nowY = e.clientY;
            let disX = nowX - that.param.currentX,
                disY = nowY - that.param.currentY;
            element.style.left = parseInt(that.param.left) + disX + "px";
            element.style.top = parseInt(that.param.top) + disY + "px";
        }

        //设定图片拖动后的位置
        function setImgLocation(){
            that.param.top = element.style.top.substring(0, element.style.top.length - 2);
            that.param.left = element.style.left.substring(0, element.style.left.length - 2);
        }
    }
}

export default MapResource
