import React from 'react';
import './Title.css';

class Title extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="title">
        <div className="title-container">
          <h1>{this.props.title}</h1>
          <span className="title-container-line"></span>
        </div>
      </div>
    );
  }
}

export default Title;
