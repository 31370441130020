import React from 'react';
import './Footer.css';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect
} from 'react-router-dom';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.footerClass_0 = 'Footer';
    this.contentClass_0 = 'footer-con';
    this.vesselClass_0 = 'footer-vessel';

    this.footerClass_1 = 'Footer_1';
    this.contentClass_1 = 'footer-con_1';
    this.vesselClass_1 = 'footer-vessel_1';
    this.state = {
      footerClass: this.footerClass_0,
      contentClass: this.contentClass_0,
      vesselClass: this.vesselClass_0,
      navigations: []
    };
  }
  componentDidMount() {
    fetch(process.env.PUBLIC_URL + '/staticData/footer/footer.json', {
      method: 'GET'
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ navigations: data.navigations });
      });

    switch (this.props.version) {
      case 0:
        this.setState({
          footerClass: this.footerClass_0,
          contentClass: this.contentClass_0,
          vesselClass: this.vesselClass_0
        });
        break;
      case 1:
        this.setState({
          footerClass: this.footerClass_1,
          contentClass: this.contentClass_1,
          vesselClass: this.vesselClass_1
        });
        break;
    }
  }
  render() {
    return (
      <div className="footer">
        <div className={this.state.footerClass}>
          <div className={this.state.contentClass}>
            <div className={this.state.vesselClass}>
              <div className={'footer-icon'}>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    '/staticImg/footer/logo_publicInstitution.png'
                  }
                />
              </div>
              <div className={'footer-content'}>
                <div id={'footer-nav'}>
                  {this.state.navigations.map((nav, key) => (
                    <div key={key} className={'footer-nav-item'}>
                      <Link to={nav.href}>{nav.name}</Link>
                    </div>
                  ))}
                </div>
                <div className={'footer-copyright'}>
                  <div className={'footer-copyright-item'}>
                    <span>主办单位：云南省自然资源厅</span>
                    <span>
                      技术支持：云南省地图院 ©All Right Reserved.盗版必究
                    </span>
                  </div>
                </div>
                <div className={'footer-company'}>
                  <div className={'footer-company-item'}>
                    <span>滇ICP备 15006972号</span>
                  </div>
                  <div className={'footer-company-item'}>
                    <span>甲测资字5300076</span>
                  </div>
                  <div className={'footer-company-item'}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/staticImg/footer/logo_police.png'
                      }
                    />
                    <a
                      href={
                        'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=53011202000676'
                      }
                      target={'_blank'}
                    >
                      滇公网安备 53011202000676号
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Footer;
