import React from "react";
import "./Support.css"
import Footer from "../../footer/Footer";
import {Carousel} from "antd";
import AboutUs from "./aboutUs/AboutUs";
import Exceptions from "./exceptions/Exceptions";
import ContactUs from "./contactUs/ContactUs";
import CopyrightNotice from "./copyrightNotice/CopyrightNotice";
import LogUpdate from "./logUpdate/LogUpdate";
import SuggestFeedback from "./suggestFeedback/SuggestFeedback";
import News from "./news/News";
import TermsService from "./termsService/TermsService";
import {Link, Route, Switch} from "react-router-dom";

class Support extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navigations: []
        }
    }

    componentDidMount() {
        fetch(process.env.PUBLIC_URL + '/staticData/footer/footer.json', {
            method: 'GET'
        }).then(res => res.json()).then(data => {
            this.setState({navigations: data.navigations})
        });
    }

    render() {
        return (
            <div className={"support"}>
                <div className={"support-container"}>
                    <div className={"support-head"}>
                        <Carousel autoplay>
                            {
                                this.state.navigations.map((nav, key) =>
                                    <Link to={nav.href} key={key}>
                                        <div style={{backgroundImage: "url(" + nav.backImg + ")"}} className={"support-head-carousel"}>
                                            {/*{nav.name}*/}
                                        </div>
                                    </Link>
                                )
                            }
                        </Carousel>
                    </div>
                    <div className={"support-centre"}>
                        {
                            this.state.navigations.map((nav, key) =>
                                this.props.location.pathname === nav.href ?
                                    <div className={"support-title"} key={key} >
                                        <span><Link to={'/'}>{"首页"}</Link></span>
                                        <span>{">>"}</span>
                                        <span className={"support-title-name"}>{nav.name}</span>
                                    </div> : false
                            )
                        }
                        <div className={"support-models"}>
                            <Switch>
                                {/* 意见反馈-关于我们-服务条款--版权申明-联系我们-更新日志-新闻 */}
                                <Route path="/support/aboutUs" component={AboutUs}/>
                                <Route path="/support/exceptions" component={Exceptions}/>
                                <Route path="/support/contactUs" component={ContactUs}/>
                                <Route path="/support/copyrightNotice" component={CopyrightNotice}/>
                                <Route path="/support/logUpdate" component={LogUpdate}/>
                                <Route path="/support/suggestFeedback" component={SuggestFeedback}/>
                                <Route path="/support/news" component={News}/>
                                <Route path="/support/termsService" component={TermsService}/>
                            </Switch>
                        </div>
                    </div>
                </div>
                <Footer version={0}/>
            </div>
        );
    }
}

export default Support;