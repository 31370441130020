import React from 'react';
import './Swiper.css';

class Swiper extends React.Component {
  constructor(props) {
    super(props);
    this.slides = this.props.slides;
    this.total = this.props.slides.length;
    this.showNum =
      this.props.showNum &&
      this.props.showNum <= this.total &&
      (this.props.showNum === 3 || this.props.showNum === 5)
        ? this.props.showNum
        : 3; // 3 | 5
    this.excessNum = parseInt(this.showNum / 2);
    this.state = {
      slides: this.props.slides,
      isHover: false,
      currentSlide: 0
    };
    this.timer = null;
    this.swiperRef = React.createRef();
  }

  componentDidMount() {
    this.initiateMove();
  }

  initiateMove() {
    if (!this.timer) {
      this.timer = setInterval(() => this.moveSwiper(), 3000);
    }
  }

  removeMove() {
    clearInterval(this.timer);
    this.timer = null;
  }

  enterSlide(index) {
    if (this.state.currentSlide === index) {
      this.removeMove();
      this.setState({ ...this.state, isHover: true });
    }
  }

  leaveSlide(index) {
    if (this.state.currentSlide === index) {
      this.initiateMove();
      this.setState({ ...this.state, isHover: false });
    }
  }

  clickSlide(index) {
    if (this.state.currentSlide !== index) {
      this.removeMove();
      this.setState({ ...this.state, currentSlide: index });
      this.initiateMove();
      this.onChangeSlide();
    }
  }

  moveSwiper() {
    const currentSlide = this.state.currentSlide;
    if (currentSlide === this.total - 1) {
      this.setState({ ...this.state, currentSlide: 0 });
    } else {
      this.setState({ ...this.state, currentSlide: currentSlide + 1 });
    }
    this.onChangeSlide();
  }

  onChangeSlide() {}

  getDirection() {
    let rightI =
      this.state.currentSlide + 1 < this.total
        ? this.state.currentSlide + 1
        : this.state.currentSlide + 1 - this.total;
    let rightII =
      this.state.currentSlide + 2 < this.total
        ? this.state.currentSlide + 2
        : this.state.currentSlide + 2 - this.total;
    let leftI =
      this.state.currentSlide - 1 >= 0
        ? this.state.currentSlide - 1
        : this.state.currentSlide - 1 + this.total;
    let leftII =
      this.state.currentSlide - 2 >= 0
        ? this.state.currentSlide - 2
        : this.state.currentSlide - 2 + this.total;

    return { rightI, rightII, leftI, leftII };
  }

  // 获取当前 Slide 位置
  getCurrentSlideDirection(index) {
    const { rightI, rightII, leftI, leftII } = this.getDirection();
    let currentS = {
      transform: 'translate3d(0px, 0px, 0px) scale(1)',
      zIndex: '10'
    };
    let rightIS = {
      transform: 'translate3d(35%, 0px, -20px) scale(0.8)',
      zIndex: '5'
    };
    let rightIIS = {
      transform: 'translate3d(60%, 0px, -40px) scale(0.6)',
      zIndex: '4'
    };
    let leftIS = {
      transform: 'translate3d(-35%, 0px, -20px) scale(0.8)',
      zIndex: '5'
    };
    let leftIIS = {
      transform: 'translate3d(-60%, 0px, -40px) scale(0.6)',
      zIndex: '4'
    };
    let excessS = {
      transform: 'translate3d(0, 0px, -20px) scale(0.8)',
      zIndex: '3'
    };
    let style = null;
    switch (index) {
      case this.state.currentSlide:
        style = currentS;
        break;
      case rightI:
        style = rightIS;
        break;
      case leftI:
        style = leftIS;
        break;
      case rightII:
        style = this.excessNum === 2 ? rightIIS : excessS;
        break;
      case leftII:
        style = this.excessNum === 2 ? leftIIS : excessS;
        break;
      default:
        style = excessS;
        break;
    }
    return style;
  }

  getMarkClass(index) {
    const { rightI, rightII, leftI, leftII } = this.getDirection();
    if (index === rightI || index === leftI) {
      return 'swiper-slide-mark-I';
    }
    if (index === rightII || index === leftII) {
      return 'swiper-slide-mark-II';
    }
    return '';
  }

  render() {
    return (
      <div className="swiper-wrapper">
        <div className="swiper-nav">
          {this.state.slides.map((slide, index) => (
            <button
              className={`swiper-nav-item ${
                this.state.currentSlide === index
                  ? 'swiper-nav-item-active'
                  : ''
              }`}
              key={index}
              onClick={this.clickSlide.bind(this, index)}
            >
              {slide.title}
            </button>
          ))}
        </div>
        <div className="swiper">
          <div className="swiper-main" ref={this.swiperRef}>
            {this.state.slides.map((slide, index) => (
              <div
                className="swiper-slide"
                key={index}
                style={this.getCurrentSlideDirection(index)}
                onMouseOver={this.enterSlide.bind(this, index)}
                onMouseLeave={this.leaveSlide.bind(this, index)}
                onClick={this.clickSlide.bind(this, index)}
              >
                <div
                  className={`swiper-slide-mark ${
                    this.state.currentSlide === index
                      ? 'swiper-slide-mark-active'
                      : ''
                  } ${
                    this.state.isHover && this.state.currentSlide === index
                      ? 'swiper-slide-mark-hover'
                      : ''
                  } ${this.getMarkClass.call(this, index)}`}
                >
                  <div className="swiper-slide-mark-text">{slide.title}</div>
                  <a
                    className="swiper-slide-mark-btn"
                    href={slide.href}
                    target={slide.target}
                  >
                    立即体验
                  </a>
                </div>
                <img className="swiper-slide-img" src={slide.img} alt="" />
              </div>
            ))}
          </div>
        </div>
        <div className="swiper-pagination">
          {this.state.slides.map((slide, index) => (
            <span
              key={index}
              className={`swiper-pagination-item ${
                this.state.currentSlide === index
                  ? 'swiper-pagination-item-active'
                  : ''
              }`}
              onClick={this.clickSlide.bind(this, index)}
            ></span>
          ))}
        </div>
      </div>
    );
  }
}

export default Swiper;
