import "./LogUpdateInfo.css"
import React from "react";
import System from "../../../config/System";
import {Divider, Image} from "antd";

class LogUpdateInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updateLogData: {
                dataStatus: null,
                deleteFlag: null,
                createBy: null,
                createTime: null,
                updateBy: null,
                updateTime: null,
                remarks: null,
                id: null,
                siteId: null,
                title: null,
                version: null,
                dataUpdateTime: null,
                dataUpdateText: null,
                dataUpdateType: null,
                dataUpdateImg: null
            },
            id: null
        }
    }

    componentDidMount() {
        // 请求单页数据并存储到state
        this.setState({id:this.props.match.params.param})
        //查询数据
        this.queryUpdateLog(this.props.match.params.param);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.param !== this.state.id) {
            this.setState({ id: nextProps.match.params.param},() => {
                //当路由参数改变时，重新请求接口获取数据
                this.queryUpdateLog(nextProps.match.params.param);
            });
        }
    }

    render() {
        return (
            <div className={"logUpdateInfo-content"}>
                <div className={"logUpdateInfo-title"}>
                    {this.state.updateLogData.title}
                </div>
                <div className={"logUpdateInfo-origin"}>
                    <div className={"logUpdateInfo-origin-time"}>
                        {"发布时间：" + this.state.updateLogData.dataUpdateTime}
                    </div>
                    <div className={"logUpdateInfo-origin-name"}>
                        {"来源： 云南省地图院"}
                    </div>
                </div>
                <div className={"logUpdateInfo-essay"}>
                    {this.state.updateLogData.dataUpdateText}
                </div>
                <Image.PreviewGroup>
                    {this.state.updateLogData.dataUpdateImg === null || undefined ? <div/> :
                        this.state.updateLogData.dataUpdateImg.split(",").map((item, index) => {
                            return (
                                <div key={index}
                                     style={{display: "block", margin: "auto", width: 400}}>
                                    <Image src={System.staticSourceUrl + item}/>
                                </div>
                            )
                        }
                    )}
                </Image.PreviewGroup>
            </div>
        )
    }

    queryUpdateLog(id) {
        fetch(System.gateway + '/tdtyns/TdtUpdateLogNew/' + id, {
            method: 'GET'
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询留言失败");
                return;
            }
            // updateLogData赋值
            let tempUpdateLogData = null;
            for (let key in data.data) {
                tempUpdateLogData = Object.assign(this.state.updateLogData, {[key]: data.data[key]});
            }
            this.setState({updateLogData: tempUpdateLogData});
        });
    }
}

export default LogUpdateInfo;
