import { createSlice } from '@reduxjs/toolkit';

//初始值
const initialState = {
    userName: undefined,
    userInfo: undefined
}

// createSlice定义子模块
export const UserSore = createSlice({
    name: 'UserSore',
    initialState: initialState,
    //修改状态的方法  state初始值 ， action传过来的参数
    reducers: {
        setUser: (state, action) => {
            state.userInfo = action.payload;
        },
        removeUser: (state,action) => {
            state.userInfo = undefined;
        },
        setName: (state, action) => {
            state.userName = action.payload;
        },
        removeName: (state,action) => {
            state.userName = undefined;
        },
    }
});

// 导出actionsCreator便于用户使用，例如：dispatch(increment())
export const { setUser, removeUser,setName,removeName} = UserSore.actions;

// 导出子模块reducer
export default UserSore.reducer;