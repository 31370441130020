import React from 'react';
import './ProduceList.css';
import Footer from "../../footer/Footer";
import System from "../../config/System";

class ProduceList extends React.Component {
    constructor(props) {
        super(props);
        this.produceListListShowClass = "produceList-content-list-show";
        this.produceListListHiddenClass = "produceList-content-list-hidden";

        this.produceListPopupShowClass = "produceList-content-popup-show";
        this.produceListPopupHiddenClass = "produceList-content-popup-hidden";

        this.produceListClassifyShowClass = "produceList-list-classify-show";
        this.produceListClassifyHiddenClass = "produceList-list-classify-hidden";
        this.state = {
            produceListClassifyClass: this.produceListClassifyShowClass,
            produceListListClass: this.produceListListShowClass,
            produceListPopupClass: this.produceListPopupHiddenClass,
            classifies: [],
            produceData: [],
            typeName: "",

            mapName: "",
            mapImg: ""
        };
        this.classifyIndex = 0;
        this.classifySelectIndex = -1;
        this.where={type_id:null};
        this.param = {
            currentX: 0,
            currentY: 0,
            left: 0,
            top: 0,
            zoomVal:1
        };
        this.isMouseDown = false;
    }

    componentDidMount(){
        //地图分类
        fetch(System.gateway + '/tdtyns/TdtMapProtype/list?delFlag=1&siteId=' + System.siteValue, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("应用领域查询失败");
                return;
            }
            this.setState({classifies: data.rows});
        });
        this.getProduceListData();
    }
    render() {
        return (
            <div className={'produceList-container'}>
                <div className={'produceList-list-container'}>
                    <div className={this.state.produceListClassifyClass}>
                        <div className={"produceList-list-classify-vessel"}>
                            <div className={"produceList-list-classify-title"}>
                                {"地图分类"}
                            </div>
                            <div className={"produceList-list-labels-con"}>
                                {
                                    this.state.classifies.map((item, key) =>
                                        <div onClick={this.classifyClick.bind(this,key,item.id,item.typeName)}
                                             className={this.state["classify_" + key] === undefined ? "produceList-list-labels-con-un" : this.state["classify_" + key]}
                                              style={{color:item.typeColor,border: "1px solid " + item.typeColor}} key={key}>
                                            {item.typeName}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className={"produceList-content"}>
                        <div className={'produceList-content-list'}>
                            <div className={'produceList-content-list-title'}>
                                {"地图产品 > " + this.state.typeName}
                            </div>
                            <div className={this.state.produceListListClass}>
                                {
                                    this.state.produceData.map((item,key)=>
                                        <div className={"produceList-content-list-item"} key={key}>
                                            <div className={"produceList-content-list-item-title"}>
                                                <span title={item.mapName}>{item.mapName}</span>
                                                <div onClick={this.produceListInfoClick.bind(this,item)}>{"查看>>"}</div>
                                            </div>
                                            <div className={"produceList-content-list-item-img"}>
                                                <img onClick={this.produceListInfoClick.bind(this,item)} src={System.staticSourceUrl + item.titleImg}/>
                                            </div>
                                            <div className={"produceList-content-list-item-des"}>
                                                <p>
                                                    <strong>{"更新日期："}</strong>
                                                    {item.mapUpdataTime}
                                                </p>
                                                <div className={"produceList-content-list-item-des-label"}>
                                                    <strong>{"标签分类："}</strong>
                                                    {
                                                        this.state.classifies.map((itemT, key) =>
                                                            parseInt(item.typeId) === itemT.id ?
                                                                <div style={{color:itemT.typeColor,border: "1px solid " + itemT.typeColor}} key={key}>
                                                                    {itemT.typeName}
                                                                </div>: false
                                                        )
                                                    }
                                                </div>
                                                <p>
                                                    <strong>{"摘要："}</strong>
                                                    {item.mapAbstract}
                                                </p>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>

                            {/*弹窗*/}
                            <div className={this.state.produceListPopupClass}>
                                <div className={"produceList-popup-title"}>
                                    <div>{this.state.mapName}</div>
                                    <i onClick={this.closePopup.bind(this)} className={"iconfont"}>&#xe607;</i>
                                </div>
                                <div onContextMenu = {(e)=> {e.preventDefault();return false;}} className={"produceList-popup-Vessel"}>
                                    <img style={{top:this.param.top,left:this.param.left,transform:"scale(" + this.param.zoomVal + ")"}}
                                         onMouseOver={this.imageMouseOver.bind(this)} src={System.staticSourceUrl + this.state.mapImg}/>
                                </div>
                            </div>
                        </div>
                        <Footer version={0}/>
                    </div>
                </div>
            </div>
        );
    }
    //查看
    produceListInfoClick(item, target){
        this.setState({produceListClassifyClass: this.produceListClassifyHiddenClass});
        this.setState({produceListListClass: this.produceListListHiddenClass});
        this.setState({produceListPopupClass: this.produceListPopupShowClass});
        this.setState({mapName: item.mapName});
        this.setState({mapImg: item.mapImg});
        this.param = {//重置参数
            currentX: 0,
            currentY: 0,
            left: 0,
            top: 0,
            zoomVal:1
        };
    }
    //关闭弹窗
    closePopup(){
        this.setState({produceListClassifyClass: this.produceListClassifyShowClass});
        this.setState({produceListListClass: this.produceListListShowClass});
        this.setState({produceListPopupClass: this.produceListPopupHiddenClass});
        this.setState({mapName: ""});
        this.setState({mapImg: ""});
    }

//    分类
    classifyClick(index,id,typeName,target){
        this.setState({["classify_" + this.classifyIndex]:"produceList-list-labels-con-un"});
        if (this.classifySelectIndex === index) {
            this.where.type_id = null;
            this.classifySelectIndex = -1;
            this.setState({typeName:""});
        }else {
            this.setState({["classify_" + index]:"produceList-list-labels-con-has"});
            this.classifyIndex = index;
            this.classifySelectIndex = index;
            this.where.type_id = id;
            this.setState({typeName:typeName});
        }
        this.getProduceListData();
    }
    //根据条件查询数据
    getProduceListData(){
        let url = System.gateway + '/tdtyns/TdtMapProduct/list?delFlag=1&siteId=' + System.siteValue;
        if (this.where.type_id !== null) {
            url = url + '&typeId=' + this.where.type_id;
        }
        fetch(url, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询数据失败");
                return;
            }
            this.setState({produceData: data.rows});
        });
    }

    //图片拖动，放大缩小
    imageMouseOver(target){
        let that = this;
        let element = target.currentTarget;

        //放大缩小
        element.addEventListener("mousewheel",function (e) {
            e.preventDefault();
            e.wheelDelta > 0 ? element.style.cursor = "zoom-in" : element.style.cursor = "zoom-out";
            that.param.zoomVal = (that.param.zoomVal + e.wheelDelta / 1200) < 1 ? 1 : that.param.zoomVal + e.wheelDelta / 1200;
            element.style.transform = "scale(" + that.param.zoomVal + ")";
        });
        element.addEventListener("mousedown",function (e) {
            e.preventDefault();
            that.isMouseDown = true;
            that.param.currentX = e.clientX;
            that.param.currentY = e.clientY;
        });
        element.addEventListener("mouseup",function (e) {
            e.preventDefault();
            that.isMouseDown = false;
            that.startX = 0;
            that.startY = 0;
            setImgLocation();
        });

        element.addEventListener("mouseleave",function (e) {
            e.preventDefault();
            that.isMouseDown = false;
            that.startX = 0;
            that.startY = 0;
            setImgLocation();
        });
        element.addEventListener("mousemove",function (e) {
            element.style.cursor = "pointer";
            e.preventDefault();
            targetMove(e);
        });

        //图片移动
        function targetMove(e) {
            if (!that.isMouseDown) {
                return;
            }
            let nowX = e.clientX,
                nowY = e.clientY;
            let disX = nowX - that.param.currentX,
                disY = nowY - that.param.currentY;
            element.style.left = parseInt(that.param.left) + disX + "px";
            element.style.top = parseInt(that.param.top) + disY + "px";
        }

        //设定图片拖动后的位置
        function setImgLocation(){
            that.param.top = element.style.top.substring(0, element.style.top.length - 2);
            that.param.left = element.style.left.substring(0, element.style.left.length - 2);
        }
    }
}
export default ProduceList;
