import React from 'react';
import './Search.css';
/**
 搜索框--------公共模块
 在父模块中使用如下
    <Search search={this.searchClick} onRef={(ref) => { this.child = ref; }}/>
    方法：
    searchClick = (searchIn) => {
        搜索的具体代码=============
        搜索成功调用---设置搜索状态 that.child.startSearch(false)
     };
 */
class Search extends React.Component {
    constructor(props) {
        super(props);
        this.clearShowClass = "search-common-clear-show";
        this.clearHiddenClass = "search-common-clear-hidden";

        this.searchIcon = '<i class="iconfont search">&#xe614;</i>';
        this.searchLoadingIcon = '<i class="iconfont search">&#xe684;</i>';
        this.state = {
            clearClass: this.clearHiddenClass,
            searchButIcon: this.searchIcon,
            searchButIconClass: "",
            isDisabled: false
        };
        this.searchValue = "";
    }
    componentDidMount(){
        this.props.onRef(this);
    }
    render() {
        return (
            <div className={"search-common"}>
                <input disabled={this.state.isDisabled} onInput={this.getSearchInputValue.bind(this)} placeholder={"请输入关键字搜索"}/>
                <button disabled={this.state.isDisabled} onClick={this.searchClick.bind(this)}  className={'search-but'}>
                    <div className={this.state.searchButIconClass} dangerouslySetInnerHTML={{__html:this.state.searchButIcon}}/>
                </button>
                <div onClick={this.clearClick.bind(this)} className={this.state.clearClass} title={"清空"}>
                    <i className={"iconfont"}>&#xe696;</i>
                </div>
            </div>
        );
    }

    //清理输入框
    clearClick(target){
        let element = target.currentTarget;
        let temp = element.parentElement.firstChild;
        temp.value = "";
        this.searchValue = "";
        this.props.search(null);
        this.setState({clearClass: this.clearHiddenClass});
    }

    //获取输入框值
    getSearchInputValue(target){
        let value = target.currentTarget.value.replace(/\s+/g,"");
        target.currentTarget.value = value;
        this.searchValue = value;
        if (value !== "") {
            this.setState({clearClass: this.clearShowClass})
        }else {
            this.props.search(null);
            this.setState({clearClass: this.clearHiddenClass});
        }
    }
    //搜索
    searchClick(target){
        if (this.searchValue === "") {
            return;
        }
        this.startSearch(true);
        this.props.search(this.searchValue)
    }
    //结束搜索
    startSearch(bool){
        if (bool) {
            this.setState({searchButIcon: this.searchLoadingIcon,searchButIconClass: "searchLoadingIcon",isDisabled:true});
        }else {
            this.setState({searchButIcon: this.searchIcon,searchButIconClass: "",isDisabled:false});
        }
    }

}
export default Search;
