import "./SupportInfo.css"
import React from "react";
import Footer from "../../footer/Footer";
import {Link, Route, Switch} from "react-router-dom";
import NewsInfo from "./news/NewsInfo";
import SuggestFeedbackInfo from "./suggestFeedback/SuggestFeedbackInfo";
import LogUpdateInfo from "./logUpdate/LogUpdateInfo";
import SuggestOffer from "./suggestFeedback/SuggestOffer";


class SupportInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navigations: []
        }
    }

    componentDidMount() {
        fetch(process.env.PUBLIC_URL + '/staticData/footer/footer.json', {
            method: 'GET'
        }).then(res => res.json()).then(data => {
            this.setState({navigations: data.navigations})
        });
    }

    render() {
        let paths = this.props.location.pathname.split("/");
        let pathStr = "/" + paths[1] + "/" + paths[2];
        return (
            <div className={"support-info"}>
                <div className={"support-info-container"}>
                    {
                        pathStr === "/supportInfo/feedback" ?
                            <div className={"support-info-title"}>
                                <span><Link to={'/'}>{"首页"}</Link></span>
                                <span>{">>"}</span>
                                <span><Link to={"/support/suggestFeedback"}>{"意见反馈"}</Link></span>
                                <span>{">>"}</span>
                                <span className={"support-info-title-name"}>{"提交建议"}</span>
                            </div> :
                            this.state.navigations.map((nav, key) =>
                                pathStr === nav.hrefInfo ?
                                    <div className={"support-info-title"} key={key}>
                                        <span><Link to={'/'}>{"首页"}</Link></span>
                                        <span>{">>"}</span>
                                        <span><Link to={nav.href}>{nav.name}</Link></span>
                                        <span>{">>"}</span>
                                        <span className={"support-info-title-name"}>{"详情"}</span>
                                    </div> : false
                            )
                    }
                    <div className={"support-info-contre"}>
                        {/*更新日志、意见反馈、新闻  详情页*/}
                        <Route path="/supportInfo/logUpdate/:param" component={LogUpdateInfo}/>
                        <Route path="/supportInfo/suggestFeedback/:param" component={SuggestFeedbackInfo}/>
                        <Route path="/supportInfo/news/:param" component={NewsInfo}/>
                        <Route path="/supportInfo/feedback" component={SuggestOffer}/>
                    </div>
                </div>
                <Footer version={0}/>
            </div>
        )
    }
}

export default SupportInfo;