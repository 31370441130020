import React from 'react';
import Title from '../title/Title';
import './MapService.css';
import Swiper from '../swiper/Swiper';

class MapService extends React.Component {
  constructor(props) {
    super(props);

    this.swipers = [
      {
        img: process.env.PUBLIC_URL+'/staticImg/homePage/mapService/model.png',
        title: '矢量地图',
        href: 'https://yunnan.tianditu.gov.cn/onlineMap?type=0',
        target: '_blank'
      },
      {
        img: process.env.PUBLIC_URL+'/staticImg/homePage/mapService/multi_temporal_imaging.png',
        title: '卫星影像',
        href: 'https://yunnan.tianditu.gov.cn/onlineMap?type=1',
        target: '_blank'
      },
      {
        img: process.env.PUBLIC_URL+'/staticImg/homePage/mapService/Terrain_dizziness.png',
        title: '地貌晕渲',
        href: 'https://yunnan.tianditu.gov.cn/onlineMap?type=2',
        target: '_blank'
      }
    ];
  }

  render() {
    return (
      <div className="map-service">
        <div className="map-service-container">
          <Title title={'地图服务'} />
          <div className="map-service-content">
            <div className="map-service-menu"></div>
            <div className="map-service-swiper">
              <Swiper slides={this.swipers} showNum={3}></Swiper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MapService;
