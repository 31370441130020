import React from 'react';
import './AppPanel.css';
import System from "../../../config/System";

class SpecialChannel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            specialChannel: [],
        };
    }
    componentDidMount() {
        let url = System.gateway + '/tdtyns/TdtApplication/list?delFlag=1&siteId=' + System.siteValue + '&isSpecial=1';
        fetch(url, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询数据失败");
                return;
            }
            this.setState({specialChannel: data.rows})
        });
    }
    render() {
        return (
            <div className={'app-panel-specialChannel'}>
                <div className={'app-panel-specialChannel-title'}>
                    <div className={"app-panel-specialChannel-title-tabs"}>
                        <span>{"专题频道"}</span>
                    </div>
                </div>
                <div className={'app-panel-specialChannel-container'}>
                    {
                        this.state.specialChannel.map((item, index) =>
                            <a href={item.appUrl} target={item.appTarget} key={index} onMouseLeave={this.specialMouseLeave.bind(this)} onMouseOver={this.specialMouseOver.bind(this)}>
                                <span>{item.applyName}</span>
                                <img src={System.staticSourceUrl + item.specialLogoImg}/>
                            </a>
                        )
                    }
                </div>
            </div>
        );
    }

    specialMouseOver(e){
        e.currentTarget.childNodes[0].style.display = "block";
        e.currentTarget.childNodes[1].style.display = "none";
    }
    specialMouseLeave(e){
        e.currentTarget.childNodes[0].style.display = "none";
        e.currentTarget.childNodes[1].style.display = "block";
    }
}
export default SpecialChannel;
