import React from 'react';
import './DataVisualization.css';
//数据可视化
class DataVisualization extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
        };
        this.serviceCenter = [];
        this.clickToEnter = {};
        this.title = "";
    }

    componentDidMount() {
        fetch(process.env.PUBLIC_URL + '/staticData/homePage/serverPanel/ServerPanel.json', {method: "GET"}).then(res => res.json()).then(data => {
            this.serviceCenter = data.items;
            this.clickToEnter = data.clickToEnter;
            this.title = data.title;
            this.setState({loaded: true});
        });
    }

    render() {
        return (
            <div className={'data-visualization-panel'}>
                <div className={'data-visualization-panel-title'}>
                    <div className={"data-visualization-panel-title-tabs"}>
                        <span>{this.title}</span>
                    </div>
                </div>
                <div className={'data-visualization-panel-container'}>
                    {
                        this.serviceCenter.map((item, index) =>
                            <div className={"data-visualization-panel-container-item"} key={index}>
                                <a href={item.href} target={item.target}>
                                    <div className={"data-visualization-panel-container-item-icon"} dangerouslySetInnerHTML={{__html:item.icon}}/>
                                    <span>{item.name}</span>
                                </a>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default DataVisualization;
