import React from 'react';
import './AppPanel.css';

import SpecialChannel from './SpecialChannel';
import ApplicationCase from './ApplicationCase';

class AppPanel extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={'app-panel'}>
                <SpecialChannel/>
                <ApplicationCase/>
            </div>
        );
    }
}
export default AppPanel;
