/**
 * 状态管理
 */
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import UserSore from './reducers/UserSore.js';
import PublicStore from './reducers/PublicStore.js';

// 创建一个store实例
const Store = configureStore({
  reducer: {
    user: UserSore,
    public: PublicStore
  }
});

export { Store };
