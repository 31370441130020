import React from 'react';
import Search from '../../common/search/Search';
import PagingButton from '../../common/paging/PagingButton';
import './DatumResource.css';
import QRCode from 'qrcode.react';
import System from "../../config/System";
import Footer from "../../footer/Footer";
import {BrowserRouter as Router, Route, Link, Switch, Redirect} from "react-router-dom";

class DatumResource extends React.Component {
    constructor(props) {
        super(props);
        this.selectedClass = "DatumResource-list-select-serviceType-selected";
        this.unselectedClass = "DatumResource-list-select-serviceType-unselected";

        this.datumResourceShowClass = "DatumResource-list-vessel-show";
        this.datumResourceHiddenClass = "DatumResource-list-vessel-hidden";

        this.showPopupClass = "DatumResource-popupShow";
        this.hiddenPopupClass = "DatumResource-popupHidden";

        this.sourceClassifyHiddenClass = "sources-list-classify-hidden";
        this.sourceClassifyShowClass = "sources-list-classify-show";

        this.state = {
            sourceClassifyClass: this.sourceClassifyShowClass,
            title: '',
            resourceType:[],
            datumResourceClass: this.datumResourceShowClass,
            popupClass: this.hiddenPopupClass,

            resource_categories_name: "应用领域：",
            resource_categories: [],

            field_application_name: "资源类别：",
            field_application: [],

            times_name: "发布时间：",
            times: [],

            resourceList: [],

            popupTitle: "",
            download_url: "",
            externalLinks: "",

        };
        this.activeTypeIndex = 0;
        this.activeType = false;
        this.activeCoverageIndex = 0;
        this.activeCoverage = false;
        this.activeYearIndex = 0;
        this.activeYear = false;

        this.queryParam = {
            resourceCategories: null,
            fieldApplication: null,
            releaseTime: null,
            keyword:null
        }
    }

    componentDidMount() {
        fetch(process.env.PUBLIC_URL + '/staticData/body/sources/ResourceType.json', {
            method: 'GET'
        }).then(res => res.json()).then(data => {
            this.setState({resourceType: data.items,title:data.title});
        });
        //获取主体分类
        fetch(System.gateway + '/tdtyns/TdtInformationDomainType/list?delFlag=1&siteId=' + System.siteValue, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("应用领域查询失败");
                return;
            }
            this.setState({resource_categories: data.rows});
        });
        fetch(System.gateway + '/tdtyns/TdtInformationMateriType/list?delFlag=1&siteId=' + System.siteValue, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("应用领域查询失败");
                return;
            }
            this.setState({field_application: data.rows});
        });
        fetch(System.gateway + '/tdtyns/TdtInformationResource/getYearList?siteId=' + System.siteValue, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询时间失败");
                return;
            }
            this.setState({times: data.data});
        });
    }

    //选择应用领域
    serviceTypeSelectedClick(item,key, target) {
        if (this.activeTypeIndex === key) {
            if (this.activeType) {
                this.setState({["resource_categories_color_" + key]: this.unselectedClass});
                this.queryParam.resourceCategories = null;
                this.activeType = false;
                this.activeTypeIndex = key;
            }else {
                this.setState({["resource_categories_color_" + key]: this.selectedClass});
                this.queryParam.resourceCategories = item.id;
                this.activeType = true;
                this.activeTypeIndex = key;
            }
        }else {
            this.setState({["resource_categories_color_" + this.activeTypeIndex]: this.unselectedClass});
            this.setState({["resource_categories_color_" + key]: this.selectedClass});
            this.queryParam.resourceCategories = item.id;
            this.activeType = true;
            this.activeTypeIndex = key;
        }
        this.queryParam.keyword = null;

        //根据条件查询
        this.getPageAmount();
    };

    //选择资源类别
    coverageAreaSelectedClick(item,key, target) {
        if (this.activeCoverageIndex === key) {
            if (this.activeCoverage) {
                this.setState({["field_application_color_" + key]: this.unselectedClass});
                this.queryParam.fieldApplication = null;
                this.activeCoverage = false;
                this.activeCoverageIndex = key;
            }else {
                this.setState({["field_application_color_" + key]: this.selectedClass});
                this.queryParam.fieldApplication = item.id;
                this.activeCoverage = true;
                this.activeCoverageIndex = key;
            }
        }else {
            this.setState({["field_application_color_" + this.activeCoverageIndex]: this.unselectedClass});
            this.setState({["field_application_color_" + key]: this.selectedClass});
            this.queryParam.fieldApplication = item.id;
            this.activeCoverage = true;
            this.activeCoverageIndex = key;
        }
        this.queryParam.keyword = null;

        //根据条件查询
        this.getPageAmount();
    };

    //选择年份
    yearSelectedClick(item,key, target) {
        if (this.activeYearIndex === key) {
            if (this.activeYear) {
                this.setState({["times_color_" + key]: this.unselectedClass});
                this.queryParam.releaseTime = null;
                this.activeYear = false;
                this.activeYearIndex = key;
            }else {
                this.setState({["times_color_" + key]: this.selectedClass});
                this.queryParam.releaseTime = item + "-1-1";
                this.activeYear = true;
                this.activeYearIndex = key;
            }
        }else {
            this.setState({["times_color_" + this.activeYearIndex]: this.unselectedClass});
            this.setState({["times_color_" + key]: this.selectedClass});
            this.queryParam.releaseTime = item + "-1-1";
            this.activeYear = true;
            this.activeYearIndex = key;
        }
        this.queryParam.keyword = null;
        //根据条件查询
        this.getPageAmount();
    };
// 隐藏分类
    hiddenClassifyVessel(){
        this.setState({sourceClassifyClass: this.sourceClassifyHiddenClass})
    }
    // 显示分类
    showClassifyVessel(){
        this.setState({sourceClassifyClass: this.sourceClassifyShowClass})
    }
    render() {
        return (
            <div className={'sources-list-container'}>
                <div className={this.state.sourceClassifyClass}>
                    <div className={"sources-list-classify-vessel"}>
                        <div className={"sources-list-classify-title"}>
                            <span>{this.state.title}</span>
                        </div>
                        <div id={"sources-list-classify-con"} className={"sources-list-classify-con"}>
                            {
                                this.state.resourceType.map((item, key) =>
                                    <Link style={{background:key===2?"#f39800":false}} key={key} to={item.href}>
                                        {item.name}
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div id={"sources-list-content"} className={"sources-list-content"}>
                    <div className={'DatumResource-list'}>
                        <div className={'DatumResource-list-title'}>
                            {"资源下载 > 资料"}
                        </div>
                        <div className={this.state.datumResourceClass}>
                            <div className={'DatumResource-list-select'}>
                                <div className={"DatumResource-list-select-serviceType-line"}>
                                    <div className={"DatumResource-list-select-serviceType"}>
                                <span className={"DatumResource-list-select-serviceType-name"}>
                                    {this.state.resource_categories_name}
                                </span>
                                    </div>
                                    <div className={"DatumResource-list-select-serviceType-con"}>
                                        {
                                            this.state.resource_categories.map((item, key) =>
                                                    <span onClick={this.serviceTypeSelectedClick.bind(this, item,key)}
                                                          className={this.state["resource_categories_color_" + key]} key={key}>
                                        {item.typeName}
                                    </span>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={"DatumResource-list-select-serviceType-line"}>
                                    <div className={"DatumResource-list-select-serviceType"}>
                                <span className={"DatumResource-list-select-serviceType-name"}>
                                    {this.state.field_application_name}
                                </span>
                                    </div>
                                    <div className={"DatumResource-list-select-serviceType-con"}>
                                        {
                                            this.state.field_application.map((item, key) =>
                                                    <span onClick={this.coverageAreaSelectedClick.bind(this, item,key)}
                                                          className={this.state["field_application_color_" + key] === undefined ? this.unselectedClass : this.state["field_application_color_" + key]}
                                                          key={key}>
                                        {item.typeName}
                                    </span>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={"DatumResource-list-select-serviceType-line"}>
                                    <div className={"DatumResource-list-select-serviceType"}>
                                <span className={"DatumResource-list-select-serviceType-name"}>
                                    {this.state.times_name}
                                </span>
                                    </div>
                                    <div className={"DatumResource-list-select-serviceType-con"}>
                                        {
                                            this.state.times.map((item, key) =>
                                                    <span onClick={this.yearSelectedClick.bind(this, item,key)}
                                                          className={this.state["times_color_" + key]} key={key}>
                                        {item + "年"}
                                    </span>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={'DatumResource-list-search'}>
                                <Search search={this.searchClick} onRef={(ref) => {this.childSearch = ref;}}/>
                            </div>
                            <div className={'DatumResource-list-content'}>
                                <div className={'DatumResource-list-content-list-items'}>
                                    {
                                        this.state.resourceList.map((item, key) =>
                                            <div className={"DatumResource-list-content-list-item"} key={key}>
                                                <div className="DatumResource-list-content-list-item-title">
                                                    <p className="title">{item.infoTitle}</p>
                                                    <span onClick={this.lookOver.bind(this, item)}
                                                          className="detail">查看&gt;&gt;</span>
                                                </div>
                                                <div className={"DatumResource-list-content-list-item-con"}>
                                                    <div className={"DatumResource-list-content-list-item-con-img"}>
                                                        <i onClick={this.lookOver.bind(this, item)} className={"iconfont"}>&#xe63f;</i>
                                                    </div>
                                                    <div className={"DatumResource-list-content-list-item-con-des"}>
                                                        <div className={"DatumResource-list-content-list-item-con-des_"}>
                                                            <span>发布时间：</span>
                                                            <p>{item.releaseTime}</p>
                                                        </div>
                                                        <div className={"DatumResource-list-content-list-item-con-des_"}>
                                                            <span>摘要信息：</span>
                                                            <p>{item.infoAbstract}</p>
                                                        </div>
                                                        <div className={"DatumResource-list-content-list-item-con-des_"}>
                                                            <span>关键字：</span>
                                                            <div className={"DatumResource-list-content-list-item-con-des_a"}>
                                                                {
                                                                    item.keyword.split(/[,| ，]/g).map((itemT, index) =>
                                                                        <span key={index}>{itemT}</span>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"DatumResource-list-content-list-item-con-show"}>
                                                        <div className={"DatumResource-list-content-list-item-con-show-QR"}>
                                                            <div
                                                                className={"DatumResource-list-content-list-item-con-show-QR_"}>扫<br/>码<br/>下<br/>载
                                                            </div>
                                                            <div className={"DatumResource-list-content-list-item-con-show-QR_a"}>
                                                                <QRCode
                                                                    value={System.staticSourceUrl + item.downloadUrl}// 生成二维码的内容
                                                                    size={95} // 二维码的大小
                                                                    fgColor="#000000" // 二维码的颜色
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className={"DatumResource-list-content-list-item-con-show-count"}>
                                                            <div title={"查看量"}
                                                                 className={"DatumResource-list-content-list-item-con-show-count_"}>
                                                                <i className={"iconfont"}>&#xe601;</i>
                                                                <span>{item.viewCount}</span>
                                                            </div>
                                                            <div title={"下载量"}
                                                                 className={"DatumResource-list-content-list-item-con-show-count_"}>
                                                                <i className={"iconfont"}>&#xe6cd;</i>
                                                                <span>{item.downloadCount}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div id={"DatumResource-list-content-pagingButton"}
                                     className={'DatumResource-list-content-pagingButton'}>
                                    <PagingButton pagingQuery={this.pagingQuery.bind(this)}
                                                  getPageAmount={this.getPageAmount.bind(this)} onRef={(ref) => {this.child = ref;}}/>
                                </div>
                            </div>
                        </div>
                        {/*弹窗---暂时没用到*/}
                        <div className={this.state.popupClass}>
                            <div className={"DatumResource-popup-title"}>
                                <div>{this.state.popupTitle}</div>
                                <i onClick={this.closePopup.bind(this)} className={"iconfont"}>&#xe607;</i>
                            </div>
                            <div className={"DatumResource-popup-Vessel"}>
                                <div className={"DatumResource-popup-con-but"}>
                                    <div className={"DatumResource-popup-but-downloadJpg"}>
                                        <div>下载</div>
                                        <i className={"iconfont"}>&#xe62b;</i>
                                    </div>
                                </div>
                                <div className={"DatumResource-popup-con-img-vessel"}>
                                    <div className={"DatumResource-popup-con-img"}>
                                        {
                                            this.state.externalLinks==="_self" &&  this.state.externalLinks!==""?
                                                "":
                                                false
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer version={0}/>
            </div>
        );
    }

    // 搜索-------功能---函数---分页===================
    searchClick = (searchIn) => {
        this.cancelSelectAll();
        this.queryParam.keyword = searchIn;
        this.getPageAmount();
    };

    //取消所有选择
    cancelSelectAll(){
        this.setState({["resource_categories_color_" + this.activeTypeIndex]: this.unselectedClass});
        this.queryParam.resourceCategories = null;
        this.activeType = false;
        this.activeTypeIndex = 0;

        this.setState({["field_application_color_" + this.activeCoverageIndex]: this.unselectedClass});
        this.queryParam.fieldApplication = null;
        this.activeCoverage = false;
        this.activeCoverageIndex = 0;

        this.setState({["times_color_" + this.activeYearIndex]: this.unselectedClass});
        this.queryParam.releaseTime = null;
        this.activeYear = false;
        this.activeYearIndex = 0;

        this.queryParam.keyword = null;
    }



    //查询数据---分页
    pagingQuery(activePage, pageSize) {
        let url = System.gateway + '/tdtyns/TdtInformationResource/list?pageNum='+activePage+'&pageSize='+pageSize+'&delFlag=1&siteId=' + System.siteValue;
        if (this.queryParam.resourceCategories !== null) {
            url = url + '&resourceCategories=' + this.queryParam.resourceCategories;
        }
        if (this.queryParam.fieldApplication !== null) {
            url = url + '&fieldApplication=' + this.queryParam.fieldApplication
        }
        if (this.queryParam.releaseTime !== null) {
            url = url + '&releaseTime=' + this.queryParam.releaseTime
        }
        if (this.queryParam.keyword !== null) {
            url = url + '&keyword=' + this.queryParam.keyword
        }
        fetch(url, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询数据失败");
                return;
            }
            console.log("resourceList===",data.rows);
            this.setState({resourceList: data.rows});
            this.childSearch.startSearch(false)
        });
    }

    // 查询总页码---加载查询列表
    getPageAmount() {
        let url = System.gateway + '/tdtyns/TdtInformationResource/getPageTotal?delFlag=1&siteId=' + System.siteValue;
        if (this.queryParam.resourceCategories !== null) {
            url = url + '&resourceCategories=' + this.queryParam.resourceCategories;
        }
        if (this.queryParam.fieldApplication !== null) {
            url = url + '&fieldApplication=' + this.queryParam.fieldApplication
        }
        if (this.queryParam.releaseTime !== null) {
            url = url + '&releaseTime=' + this.queryParam.releaseTime
        }
        if (this.queryParam.keyword !== null) {
            url = url + '&keyword=' + this.queryParam.keyword
        }

        fetch(url, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查询数据失败");
                return;
            }
            let amountPage = Math.floor((data.data-1) / 10) + 1;
            this.child.setAmountPage(amountPage);
        });
    }

    //查看按钮
    lookOver(item, target) {
        // this.setState({datumResourceClass: this.datumResourceHiddenClass});
        // this.setState({popupClass: this.showPopupClass});
        // this.setState({popupTitle: item.infoTitle});
        // this.setState({externalLinks: item.externalLinks});
        // this.setState({download_url: item.downloadUrl});

        if (item.externalLinks === "_self") {
            window.open(System.staticSourceUrl + item.downloadUrl,"_blank");
        }else {
            window.open(item.downloadUrl,"_blank");
        }

        let url = System.gateway + '/tdtyns/TdtInformationResource/getViewCount?id=' + item.id;
        fetch(url, {
            method:'GET',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
        }).then(res => res.json()).then(data => {
            if (data.code !== 200) {
                alert("查看次数统计失败");
                return;
            }
            console.log("查看次数统计成功");
        });
    }
    //关闭弹窗按钮
    closePopup(target){
        // this.setState({datumResourceClass: this.datumResourceShowClass});
        // this.setState({popupClass: this.hiddenPopupClass,popupTitle: ""});
        // this.setState({externalLinks: ""});
        // this.setState({download_url: ""});
    }
}

export default DatumResource
