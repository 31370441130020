import Cookies from 'js-cookie'

/**  天地图   */
const TokenKey = 'tdt-admin-token'
export function getToken() {
    return Cookies.get(TokenKey)
}
export function setToken(token) {
    return Cookies.set(TokenKey, token, { path: '/' })
}
export function removeToken() {
    return Cookies.remove(TokenKey, { path: '/' })
}

/**  政务   */
const TokenZWKey = 'zw-admin-token'
export function getZWToken() {
    return Cookies.get(TokenZWKey)
}

export function setZWToken(token) {
    return Cookies.set(TokenZWKey, token, { path: '/' })
}

export function removeZWToken() {
    return Cookies.remove(TokenZWKey, { path: '/' })
}