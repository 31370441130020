import React from 'react';
import './CopyrightNotice.css';

class CopyrightNotice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            copyrightNotice: []
        }
    }
    componentDidMount(){
        fetch(process.env.PUBLIC_URL + '/staticData/body/support/copyrightNotice/CopyrightNotice.json', {
            method: 'GET'
        }).then(res => res.json()).then(data => {
            this.setState({copyrightNotice: data.copyrightNotice});
        })
    }
    render() {
        return (
            <div className={"copyrightNotice-content"}>
                {
                    this.state.copyrightNotice.map((copyrightNotice, key) =>
                        copyrightNotice.childs ?
                            <p key={key} className={"copyrightNotice-content-paragraph-tow"}>
                                {copyrightNotice.paragraph}
                            </p>:
                            <p key={key} className={"copyrightNotice-content-paragraph"}>
                                {copyrightNotice.paragraph}
                            </p>
                    )
                }
            </div>
        );
    }
}
export default CopyrightNotice;
