import { createSlice } from '@reduxjs/toolkit';

//初始值
const initialState = {
  activePage: '/home'
};

// createSlice定义子模块
export const UserSore = createSlice({
  name: 'UserSore',
  initialState: initialState,
  //修改状态的方法  state初始值 ， action传过来的参数
  reducers: {
    setPage: (state, action) => {
      state.activePage = action.payload;
    }
  }
});

// 导出actionsCreator便于用户使用，例如：dispatch(increment())
export const { setPage } = UserSore.actions;

// 导出子模块reducer
export default UserSore.reducer;
